.containerAnimacion{
    margin-top: 10vh;
    min-height: 80vh;
    min-width: 50vw;
    max-width: 50vw;
    margin-left: 0vw;
    margin-left: -9vw;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;  
  
}



@media (max-width: 1400px) {
    .containerAnimacionGeneral{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        min-width: 50vw;
        margin-left: -7vw;
        .Espacio{
            min-width: 0vw;
        }
        .containerAnimacion{
            margin-top: 25vh;
            min-height: 40vh;
            max-height: 40vh;
            min-width: 50vw;
            max-width: 50vw;
            display: flex;
            justify-content: center;
            align-items: flex-end;
        }   
    }
   
}


@media (max-width: 1200px ) and  (orientation:landscape) {
    .containerAnimacionGeneral{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        min-width: 55vw;
        margin-left: -8vw;
        .Espacio{
            min-width: 0vw;
        }
        .containerAnimacion{
            margin-top: 25vh;
            min-height: 40vh;
            max-height: 40vh;
            min-width: 50vw;
            max-width: 50vw;
            display: flex;
            justify-content: center;
            align-items: flex-end;
        }   
    }
   
}


@media (max-width: 820px ) and  (orientation:portrait) {
    .containerAnimacionGeneral{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        min-width: 50vw;
        margin-left: -0vw;
        .Espacio{
            min-width: 0vw;
        }
        .containerAnimacion{
            margin-top: 10vh;
            min-height: 40vh;
            max-height: 40vh;
            min-width: 50vw;
            max-width: 50vw;
            display: flex;
            justify-content: center;
            align-items: center;
        }   
    }
   
}


@media (max-width: 700px) {
    .containerAnimacionGeneral{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        min-width: 90vw;
        .Espacio{
            min-width: 0vw;
        }
        .containerAnimacion{
            margin-top: 10vh;
            min-height: 40vh;
            max-height: 40vh;
            min-width: 90vw;
            max-width: 90vw;
            display: flex;
            justify-content: center;
            align-items: flex-end;
        }   
    }
   
}