#navbar{
    font-family: 'open sans';
    width: 100%;
   
    display: flex;
    align-items: center;
    position: fixed;
    top:0;
}

    /***************       NORMAL    ********************/
    /*     HEADER CLOSE     */
    
    .HeaderNavContainer, .HeaderNavContainerWhite, .HeaderNavContainerBlack{
        position: absolute;
        z-index: 160;
        min-width: 100vw;
        max-width: 100vw;
        min-height: 8vh;
        .navigationMenu{
            min-width: 100vw;
            max-width: 100vw;
            .MenuBottoms{
                display: flex;
                flex-direction: row;
                .containerTBE{
                    cursor: pointer;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    background-color: #FF4700;
                    min-height: 8vh;
                    min-width: 7vw;
                    .img{
                       
                        min-width: 4vw;
                        max-width: 4vw;
                    }
                }
                .containerLinks{
                    flex-grow: 3;
                    display: flex;
                    justify-content: flex-end;                
                    align-items: center;
                    background-color: #FF4700;
                    padding-right: 5vw;
                    .imagen{
                        margin-left: 2vw;
                        width: 2vw;
                        img{
                            min-width: 75%;
                        }
                    }
                    .imagenMovil{
                        display: none;
                    }
                }
                .containerBotton{
                    background-color: #FF4700;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    min-width: 5vw;
                    color: #FFF;
                }
            }
        }


        .MenuTitleHeaders{

            background-color: #FF4700;
            display: flex;
            flex-direction: row;
            min-height:  0vh;
            max-height:  0vh;
            animation: ocultarMenu 0.8s;
            div{
                width: 30vw;
            }
            .elementThree {
                
                min-height: 0vh;
                max-height: 0vh;
                flex-grow: 2;
                display: flex;
                flex-direction: column;
                ul{
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    width: 100%;
                    min-height: 80%;
                   // animation: ocultarTitleClose 0.8s;
                    li{
                        cursor: pointer;
                        width: 60%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        
                        border: none;
                        list-style: none;
                        font-size: 3rem;
                        font-family: Montserrat;
                        min-height: 25%;
                        font-weight: 600;
                       
                        background-size:    100% 100%;//,
                        background-position:   0% 90%;                
                    }

                    li {
                        margin: 0;
                        padding: 0;
                        background-repeat: no-repeat;
                        background-image:   linear-gradient(to right, #C9C9C9 100%, #C9C9C9 100%);
                        background-size:    100% .5%;//,
                        background-position:   0% 99%;      
                        
                        font-size: 0rem;
                        .Links{
                            font-size: 0rem;
                            //animation: desaparecerTexto 0.5s;
                        }
                        @keyframes desaparecerTexto {
                            0%{
                                font-size: 2.5rem;
                            }
                            10%{
                                background-color: none;
                                color: transparent;
                            }
                            100%{
                                color: transparent;
                                background-color: none;
                                font-size: 2.5rem;
                            }                            
                        }
                    }
                    
                    li:hover {
                        .Links{
                            color:#000;
                        }
                    }

                    .Links{
                        margin: 0;
                        padding: 0;
                        background-repeat: no-repeat;
                        background-image:   linear-gradient(to right, #C9C9C9 100%, #C9C9C9 100%);
                        background-size:    100% .5%;//,
                        background-position:   0% 99%;          
                        min-height: 100%;
                        min-width: 100%;
                        display: flex;
                        align-items: center;
                    }
                }    
                
                
                div{
                    display: flex;
                    justify-content: flex-start;                  
                    .imagen{
                        color: #000;
                        width: 6%;
                        margin-right: 10%;
                    }                   
                    
                }
                
            }                   
        }       

    }
    .HeaderNavContainerWhite{      
        .navigationMenu{     
            .MenuBottoms{            
                .containerTBE{                  
                    background-color: #FFF;               
                }
                .containerLinks{
                    background-color: #FFF;                 
                }
                .containerBotton{
                    background-color: #FFF;                   
                }
            }
        }

    }
    .HeaderNavContainerBlack{      
        .navigationMenu{     
            .MenuBottoms{            
                .containerTBE{                  
                    background-color: #000;               
                }
                .containerLinks{
                    background-color: #000;                 
                }
                .containerBotton{
                    background-color: #000;                   
                }
            } 
        }

    }


    /*      HEADER OPEN       */
   
    .HeaderNavContainerOpen,  .HeaderNavContainerOpenWhite,  .HeaderNavContainerOpenBlack{
       // transition: all 2s;
       
        z-index: 150;
        min-width: 100vw;
        max-width: 100vw;
        min-height: 8vh;
        display: flex;
        flex-direction: column;
        //background-color: rgba( 255,71,0,  1);

        border-bottom: 1.5px solid rgba( 255,255,255,  0.8);
        z-index: 80;
        
        //filter: blur(10px);
        .navigationMenu{
            min-width: 100vw;
            max-width: 100vw;
            z-index: 80;
            background-color: rgba( 255,71,0,  1);
    

            .MenuBottoms{
                z-index: 80;
                display: flex;
                flex-direction: row;
                min-height: 8vh;             
                .containerTBE{
                    z-index: 80;
                    cursor: pointer;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    min-width: 7vw;
                    img{
                        min-width: 4vw;
                        max-width: 4vw;
                    }
                }
                .containerLinks{
                    flex-grow: 3;
                    display: flex;
                    justify-content: flex-end;                
                    align-items: center;
                    padding-right: 5vw;
                    img{
                    
                        margin-left: 2vw;
                        min-width: 2vw;
                    }
                    
                }
                .containerBotton{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 5vw;
                    color: #FFF;

                }
            }
        }


        .MenuTitleHeaders{
            display: flex;
            flex-direction: row;
            min-height:  40vh;
            animation: MenuContener 0.8s;
            div{
                width: 30vw;
                
            }
            .elementThree {

                flex-grow: 2;
                display: flex;
                flex-direction: column;
                ul{
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    width: 100%;
                    min-height: 80%;
                   
                    li{
                        cursor: pointer;
                        width: 60%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        
                        border: none;
                        list-style: none;
                        font-size: 3rem;
                        font-family: Montserrat;
                        min-height: 25%;
                        font-weight: 600;
                       
                        background-size:    100% 100%;//,
                        background-position:   0% 90%;                
                    }

                    li {
                        margin: 0;
                        padding: 0;
                        background-repeat: no-repeat;
                        background-image:   linear-gradient(to right, #C9C9C9 100%, #C9C9C9 100%);
                        background-size:    100% .5%;//,
                        background-position:   0% 99%;        
                        .Links{
                            //animation: aparecerTitleHeader 0.5s;  
                        }
                          
                    }
                    
                    li:hover {
                        //animation: TitleHov 1.25s;                      
                        .Links{
                            color:#000;
                        }
                    }

                    .Links{
                        margin: 0;
                        padding: 0;
                        background-repeat: no-repeat;
                        background-image:   linear-gradient(to right, #C9C9C9 100%, #C9C9C9 100%);
                        background-size:    100% .5%;//,
                        background-position:   0% 99%;          
                        min-height: 100%;
                        min-width: 100%;
                        display: flex;
                        align-items: center;
                    }
                    
                }    
                
                
                div{
                    display: flex;
                    justify-content: flex-start;                  
                    .imagen{
                        width: 6%;
                        margin-right: 10%;
                        img{
                            min-width: 150%;
                        }
                    }                   
                    .imagenMovil{
                        display: none;
                    }
                }
                
            }                   
        }        
    }
    @keyframes aparecerTitleHeader{
        0% {
            display: none;
            color: transparent;
        }
        70%{
            color: transparent;
        }
        100% {
            display: flex;
           
        }
    }
    @keyframes ocultarTitleClose{
        0% {
            display: flex;
            min-height:  30vh;
            max-height:  30vh;
        }
        
        100% {
            display: none;
            min-height:  0vh;
            max-height:  0vh;
        }
    }
    @keyframes ocultarMenu {
        0% {
            display: flex;
            min-height:  40vh;
            max-height:  40vh;
        }
        
        100% {
            display: flex;
            min-height:  0vh;
            max-height:  0vh;
        }
    }

    @keyframes TitleHov {
        0% {
            display: none;
            color: transparent;
            background-size:  0% 1.5px;
        }             
        90%{
            display: none;
            color: transparent;
        }                          
        100% {
            display: flex;
            color: transparent;
            background-size:    100% 1.5px;     
        }
    }
    @keyframes MenuContener {
        0% {
            min-height:  0vh;
            max-height:  0vh;
        }
        
        100% {
            min-height:  40vh;
            max-height:  40vh;
        }
    }
    
    @keyframes AparecerLogos {
        0% {
           margin-bottom: -10vh;
        }
        100% {
        margin-bottom: 0vh;
        }
    }





.filtro{
    
    margin-top: 0;
    z-index: -1;
    min-height: 100vh;
    min-width: 100vw;
    background-color: rgba(0,0,0,0.5);
}































