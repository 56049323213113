*{
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

@font-face {
  font-family: SummerFaith-Regular;
  src: local('fonts'),
   url("./fonts/Summer-Faith.otf") format("opentype");
}

@font-face {
  font-family: Montserrat;
  src: local('fonts'),
   url("./fonts/Montserrat-Regular.otf") format("opentype");
}
@font-face {
  font-family: SummerFaith;
  src: local('fonts'),
   url("./fonts/Summer Faith.ttf") format("truetype");
}



@font-face {
  font-family: HelveticaRegular;
  src: local('fonts'),
   url("./fonts/Helvetica.ttf") format("opentype");
}

@font-face {
  font-family: HelveticaNeue;
  src: local('fonts'),
   url("./fonts/HelveticaNeue.otf") format("opentype");
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',SummerFaith-Regular,
    monospace,MiFuentePersonal,Montserrat,SummerFaith,  HelveticaRegular, HelveticaNeue;    
}


/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/

.body::-webkit-scrollbar {
  -webkit-appearance: none;
}

body::-webkit-scrollbar:vertical {
  width:10px;
  display: none;
}

body::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
  display: none;
} 

body::-webkit-scrollbar:horizontal {
  height: 10px;
  display: none;
}

body::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

body::-webkit-scrollbar-track {
  border-radius: 10px;  
}



@media (max-width:700px){

  
  body {
    position: absolute;
    margin: 0;
    padding: 0;
    min-width: 100vw;
    max-width: 100vw;
    overflow-y: scroll;
    overflow-x: scroll;
    display: flex;
    justify-content: center;
    align-items:  center;    
    background-color: transparent;
    flex-direction: column;
  }
  
  
}