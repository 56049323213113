@media (max-width: 1180px)and(orientation: landscape) {



    .containerFormContact{
        min-height: 50vh;
        color: white;
        display: flex;
        padding-top: 15vh;
        flex-direction: column;
        align-items: center;
    
        .formTitle{
            width: 80%;
            border-top: 1px solid rgba(255,255,255, .3);
            display: flex;
            flex-direction: row;
            .formTitleLeft{
                width: 50%;
                display: flex;
                height: 8vh;
                align-items: center;
                justify-content: flex-start;
                font-family: Montserrat;
                text-align: right;
                width: 50%;
                font-size: 1.2rem;
            }
            .formTitleRight{
                display: flex;
                height: 8vh;
                align-items: center;
                justify-content: flex-end;
                font-family: Montserrat;
                text-align: right;
                width: 50%;
                font-size: 1.2rem;
                color: #FF4700;
            }
        }
    
        .containerFormContent{
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            width: 80vw;
            margin-top: 5vh;
        
    
          
    
            .containerFormContentLeft{
            
    
    
            .my-popup-content {
                margin: auto;
                width: 50%;
                padding: 5px;
              }
              .my-popup-arrow {
                color: rgb(255, 255, 255);
              }
              [role='tooltip'].my-popup-content {
                width: 200px;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
              }
              
              .my-popup-overlay {
                background: rgba(0, 0, 0, 0.5);
              }
              [data-popup='tooltip'].my-popup-overlay {
                background-color: transparent;
              }
    
            
    
            .popup-content {
                border-radius: 5px;
                padding: 0.5rem;
              }
              
              html[data-theme='dark'] .popup-content {
                background-color: rgb(41, 45, 62);
                color: #fff;
                border: 1px solid #9a9595;
              }
              
              html[data-theme='light'] .popup-content {
                background-color: #fff;
                color: #000;
                border: 1px solid #d7d7d7;
              }
              
              html[data-theme='dark'] .popup-arrow > svg {
                color: rgb(41, 45, 62);
                stroke-width: 2px;
                stroke: #9a9595;
                stroke-dasharray: 30px;
                stroke-dashoffset: -54px;
              }
              
              html[data-theme='light'] .popup-arrow > svg {
                color: #fff;
                stroke-width: 2px;
                stroke: #d7d7d7;
                stroke-dasharray: 30px;
                stroke-dashoffset: -54px;
              }
    
            }
              /*estilos popup*/
    
    
    /* ESTILOS POPUP*/
    
    /*FIN DE ESTILOS POPPUO*/
    
    
    
              
            .containerFormContentLeft{
                display: flex;
                flex-direction: column;
                min-width: 80vw;
    
                /*Fin de estilos popup*/
                .subtitleForm{
                    min-width: 40vw;
                    display: flex;
                    flex-direction: row;
                    text{
                        min-width: 40vw;
                        font-size: 1.1rem;
                        font-weight: 200;
                        font-family: Montserrat;
                        color: #FFFFFF;
                        .gray{
                           color: #707070;
                       }
                    }
                }
                .inputForm{                    
                    min-width: 40vw;
                    display: flex;
                    flex-direction: row;
                    input{
                        flex-grow: 1;
                        font-size: 1.2rem;
                        font-family: Montserrat;
                        min-height: 40px;
                        margin-top: 5px;
                        margin-right: 5px;
                        margin-bottom: 20px;
                        color: #FFFFFF;
                    }
                    .message{
                        min-height: 10vh;
                    }
                    input[type="text"] , input[type="date"]{    
                        background-color : #707070;
                        color: white;
                        font-size: 1rem;
                        padding-left: 10px;
                        border:0px;   
                      }
                      input[type="date"]{    
                        background-color : #707070;
                        color: rgb(255, 255, 255,0.6);
                        font-size: 1rem;
                        padding-left: 10px;
                        border:0px;   
                      }                                               
                }
    
    
                .inputRadius{
                    min-width: 40vw;
                    display: flex;
                    flex-direction: row;
                    div{
                        flex-grow: 1;
                        display: flex;
                        flex-direction: column;
                       
                        .title{
                            font-family:  HelveticaNeue;
                            margin-bottom: 15px;
                        }
                    }
                    div>label{
                        font-family: HelveticaRegular;                
                    }                             
                }
                
                button[type="submit"]{  
                    background-color: transparent; 
                    cursor: pointer; 
                    position: relative;
                    color: white;
                    font-size: 1.5rem;
                    text-align: left;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    min-height: 50px;
                    min-width: 20%;
                    max-width: 50%;
                    border:0px; 
                    text{                    
                        width: 95%;
                        margin-right: 0%;
                    }
                    border-bottom: 1px solid rgba(255,255,255, .3);
                }
            }
            .containerFormContentRight{
                display: none;
                min-width: 40%;
                min-height: 30vh;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: SummerFaith;
                font-weight: 500;
                font-size: 10rem;
                line-height: 6rem;
                margin-left: 10%;
                text-align: center;
                display: none;
            }
        }
    }
    input::placeholder {
        color: rgb(255, 255, 255,0.6);
        font-weight: 400;
        font-size: 0.9rem;
      }
      .message {    
        margin: 0;
        padding: 0;
      }
      .textarea{
          width: 100%;
        font-weight: 400;
        font-size: 1rem;
        padding-top: 10px;
        padding-left: 10px;
        margin-top: 5px;
        margin-bottom: 10px;
        font-family: Helvetica;
        color: rgb(255, 255, 255,0.6);
        background-color: #707070;
      }
    .boton{
        background-color: #FF4700;
    }
    
    
    .containerFormContactMovil{
        display: none;
    }
    #contenPopContainer{
    }
    #containerPop{
      max-width: 20vw;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #000;
      padding: 0;
      margin: 0;
    }
    
    
    
    
    
    


}


















@media (max-width: 820px)and(orientation: portrait) {
  .containerFormContact{
      min-height: 50vh;
      color: white;
      display: flex;
      padding-top: 15vh;
      flex-direction: column;
      align-items: center;
  
      .formTitle{
          width: 80%;
          border-top: 1px solid rgba(255,255,255, .3);
          display: flex;
          flex-direction: row;
          .formTitleLeft{
              width: 50%;
              display: flex;
              height: 8vh;
              align-items: center;
              justify-content: flex-start;
              font-family: Montserrat;
              text-align: right;
              width: 50%;
              font-size: 1.2rem;
          }
          .formTitleRight{
              display: flex;
              height: 8vh;
              align-items: center;
              justify-content: flex-end;
              font-family: Montserrat;
              text-align: right;
              width: 50%;
              font-size: 1.2rem;
              color: #FF4700;
          }
      }
  
      .containerFormContent{
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          width: 80vw;
          margin-top: 5vh;
      
  
        
  
          .containerFormContentLeft{
          
  
  
          .my-popup-content {
              margin: auto;
              width: 50%;
              padding: 5px;
            }
            .my-popup-arrow {
              color: rgb(255, 255, 255);
            }
            [role='tooltip'].my-popup-content {
              width: 200px;
              box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
            }
            
            .my-popup-overlay {
              background: rgba(0, 0, 0, 0.5);
            }
            [data-popup='tooltip'].my-popup-overlay {
              background-color: transparent;
            }
  
          
  
          .popup-content {
              border-radius: 5px;
              padding: 0.5rem;
            }
            
            html[data-theme='dark'] .popup-content {
              background-color: rgb(41, 45, 62);
              color: #fff;
              border: 1px solid #9a9595;
            }
            
            html[data-theme='light'] .popup-content {
              background-color: #fff;
              color: #000;
              border: 1px solid #d7d7d7;
            }
            
            html[data-theme='dark'] .popup-arrow > svg {
              color: rgb(41, 45, 62);
              stroke-width: 2px;
              stroke: #9a9595;
              stroke-dasharray: 30px;
              stroke-dashoffset: -54px;
            }
            
            html[data-theme='light'] .popup-arrow > svg {
              color: #fff;
              stroke-width: 2px;
              stroke: #d7d7d7;
              stroke-dasharray: 30px;
              stroke-dashoffset: -54px;
            }
  
          }
            /*estilos popup*/
  
  
  /* ESTILOS POPUP*/
  
  /*FIN DE ESTILOS POPPUO*/
  
  
  
            
          .containerFormContentLeft{
              display: flex;
              flex-direction: column;
              min-width: 80vw;
  
              /*Fin de estilos popup*/
              .subtitleForm{
                  min-width: 40vw;
                  display: flex;
                  flex-direction: row;
                  text{
                      min-width: 40vw;
                      font-size: 1.1rem;
                      font-weight: 200;
                      font-family: Montserrat;
                      color: #FFFFFF;
                      .gray{
                         color: #707070;
                     }
                  }
              }
              .inputForm{                    
                  min-width: 40vw;
                  display: flex;
                  flex-direction: row;
                  input{
                      flex-grow: 1;
                      font-size: 1.2rem;
                      font-family: Montserrat;
                      min-height: 40px;
                      margin-top: 5px;
                      margin-right: 5px;
                      margin-bottom: 20px;
                      color: #FFFFFF;
                  }
                  .message{
                      min-height: 10vh;
                  }
                  input[type="text"] , input[type="date"]{    
                      background-color : #707070;
                      color: white;
                      font-size: 1rem;
                      padding-left: 10px;
                      border:0px;   
                    }
                    input[type="date"]{    
                      background-color : #707070;
                      color: rgb(255, 255, 255,0.6);
                      font-size: 1rem;
                      padding-left: 10px;
                      border:0px;   
                    }                                               
              }
  
  
              .inputRadius{
                  min-width: 40vw;
                  display: flex;
                  flex-direction: row;
                  div{
                      flex-grow: 1;
                      display: flex;
                      flex-direction: column;
                     
                      .title{
                          font-family:  HelveticaNeue;
                          margin-bottom: 15px;
                      }
                  }
                  div>label{
                      font-family: HelveticaRegular;                
                  }                             
              }
              
              button[type="submit"]{  
                  background-color: transparent; 
                  cursor: pointer; 
                  position: relative;
                  color: white;
                  font-size: 1.5rem;
                  text-align: left;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  min-height: 50px;
                  min-width: 20%;
                  max-width: 50%;
                  border:0px; 
                  text{                    
                      width: 95%;
                      margin-right: 0%;
                  }
                  border-bottom: 1px solid rgba(255,255,255, .3);
              }
          }
          .containerFormContentRight{
              display: none;
              min-width: 40%;
              min-height: 30vh;
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: SummerFaith;
              font-weight: 500;
              font-size: 10rem;
              line-height: 6rem;
              margin-left: 10%;
              text-align: center;
              display: none;
          }
      }
  }
  input::placeholder {
      color: rgb(255, 255, 255,0.6);
      font-weight: 400;
      font-size: 0.9rem;
    }
    .message {    
      margin: 0;
      padding: 0;
    }
    .textarea{
        width: 100%;
      font-weight: 400;
      font-size: 1rem;
      padding-top: 10px;
      padding-left: 10px;
      margin-top: 5px;
      margin-bottom: 10px;
      font-family: Helvetica;
      color: rgb(255, 255, 255,0.6);
      background-color: #707070;
    }
  .boton{
      background-color: #FF4700;
  }
  
  
  .containerFormContactMovil{
      display: none;
  }
  #contenPopContainer{
  }
  #containerPop{
    max-width: 20vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    padding: 0;
    margin: 0;
  }
  
  
  
  
  
  


}