@media (max-width: 1180px)and(orientation: landscape) {
    .containerContentPageSeven{
        min-width: 100vw;
        min-height: 100vh;
        max-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        background-color: transparent;
    }
    .containerInformation{
        z-index: 50;
        width: 90vw;
        height: 52vh;
        .titleInformation{
            width: 100%;
            height: 50%;
            display: flex;
            justify-content: center;
            img{
                width: 40%;
            }
        }
        .informationBotton{
            width: 100%;
            height: 50%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
                .textTwo{
                color: #FFF;
                font-size: 1.8rem;
                font-family: Montserrat;
                text{
                    font-family: Montserrat;
                    font-size: 1rem;    
                }
            }
            .iconsBottoms{
                justify-content: center;
                padding-left: 2.5vw;
                a{
                max-width: 5vw;
                min-width: 5vw;
                display: flex;
                justify-content: flex-start;
                    img{
                        width: 40%;
                        min-width: "100px";
                        max-width: "100px";
                    }
                    .beImage{
                        width: 50%;
                    }
                }
            }
            .textThree{            
                font-size: 0.9rem;
                font-family: Montserrat;
                margin-bottom: 2%;
                margin-top: 2%;
                color: #FFF;
                text{
                    color: #FFF;
                    font-family: Montserrat;
                    font-size: 1rem;
                }
            }
        }
        
    }
    
    .bottonend{
        height: 100%;
        min-width: 90vw;
        min-height: 5vh;
        display: flex;
        justify-content: center;
        align-items: center;
        .designBy{
            z-index: 20;
            min-width: 15vw;
            margin-left: 75vw;
            .st1{
                fill: #FFF;
            }
            .st2{
                fill: #FFF;
            }
        }
    }
}















@media (max-width: 820px ) and  (orientation:portrait) {

    .containerContentPageSeven{
        min-width: 100vw;
        min-height: 100vh;
        max-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        background-color: transparent;
    }
    .containerInformation{
        z-index: 50;
        width: 90vw;
        height: 52vh;
        .titleInformation{
            width: 100%;
            height: 50%;
            display: flex;
            justify-content: center;
            img{
                width: 70%;
            }
        }
        .informationBotton{
            width: 100%;
            height: 50%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
                .textTwo{
                color: #FFF;
                font-size: 1.8rem;
                font-family: Montserrat;
                text{
                    font-family: Montserrat;
                    font-size: 1rem;    
                }
            }
            .iconsBottoms{
                justify-content: center;
                padding-left: 2.5vw;
                a{
                max-width: 5vw;
                min-width: 5vw;
                display: flex;
                justify-content: flex-start;
                    img{
                        width: 70%;
                        min-width: "100px";
                        max-width: "100px";
                    }
                    .beImage{
                        width: 80%;
                    }
                }
            }
            .textThree{            
                font-size: 0.9rem;
                font-family: Montserrat;
                margin-bottom: 2%;
                margin-top: 2%;
                color: #FFF;
                text{
                    color: #FFF;
                    font-family: Montserrat;
                    font-size: 1rem;
                }
            }
        }
        
    }
    
    .bottonend{
        height: 100%;
        min-width: 90vw;
        min-height: 5vh;
        display: flex;
        justify-content: center;
        align-items: center;
        .designBy{
            z-index: 20;
            min-width: 25vw;
            margin-left: 65vw;
            .st1{
                fill: #FFF;
            }
            .st2{
                fill: #FFF;
            }
        }
    }
}