


@media (max-width: 700px) {

    .containerContentPageTwo{
    min-height: 70vh;
    margin-bottom: 10vh;
    }
    
    .titlePageTwo{        
        max-width: 85vw;
        max-height: 15vh;
        min-height: 15vh;
        img{
            max-width: 3vw;
            margin-right: 2vw;
        }
    }
    .containerSubContentPageTwo{
        display: flex;
        align-items: center;
        max-width: 85vw;
        min-width: 85vw;
        .subtitleContainerPageTwo{
            font-size: 2rem;
            text-align: left;
            max-width: 85vw;
            min-width: 85vw;
            color: #FFF;
            margin-bottom: 10vw;
            h1{
             font-family: Montserrat;
            }
        }
    }
    
  
    .optionSelectSubtitle{
       display: none;
    }



    .optionSelectSubtitleMovil{
        width: 85vw;
        display: flex;
        color: #FFF;
        margin: 0;
        padding: 0;
        ul{
            width: 85vw;
            margin: 0;
            padding: 0;

            
            .opcionesDesplegadas{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;                
                padding-bottom: 6vh;
                max-width: 85vw;
                text{
                    max-width: 75vw;
                    min-width: 75vw;
                    color: #FA500F;
                 
                }
                .img{
                    min-width: 9vw;
                    max-width: 9vw;
                    img{
                        position: absolute;
                        width: 20px;
                        height: 20px;
                    }
                }
                .textContainerDesplegado{
                    display: flex;
                    color: #FFF;
                    flex-direction: column;
                    div{
                        margin-bottom: 1vh;
                    }
                    .titleDesplegado{
                        font-family: Montserrat;
                        font-weight: 300;
                        font-size: 1rem;
                        min-height: 2vh;
                        max-height: 2vh;
                        animation: crecer 1s;
                    }
                    .subtitleDesplegado{
                        color: #FA500F;
                        font-family: Montserrat;
                        font-weight: 400;
                        font-size: 1rem;
                        min-height: 2vh;
                        max-height: 2vh;
                        animation: crecerDos 1.5s;
                    }
                    .textDesplegado{
                        font-size: 1rem;
                        min-width: 90%;
                        max-width: 90%;
                        text-align: justify;
                        color: #CBCBCB;
                        min-height: 6vh;
                        max-height: 6vh;
                        font-family: HelveticaNeue;
                        animation: crecerTres 0.9s;
                    }


                    @keyframes crecer {
                        0%{
                            color: transparent;
                            min-height: 0vh;
                            max-height: 0vh;
                        }
                        80%{
                            color: transparent;
                        }
                        100%{
                            color: #fff;
                            min-height: 2vh;
                            max-height: 2vh;
                        }
                    }
                    @keyframes crecerDos {
                        0%{
                            color: transparent;
                            min-height: 0vh;
                            max-height: 0vh;
                        }
                        50%{
                            color: transparent;
                        }
                        100%{
                            color: #FA500F;
                            min-height: 2vh;
                            max-height: 2vh;
                        }
                    }
                    @keyframes crecerTres {
                        0%{
                            color: transparent;
                            min-height: 0vh;
                            max-height: 0vh;
                        }
                        90%{
                            color: transparent;
                        }
                        100%{
                            color: #C9C9C9;
                            min-height: 6vh;
                            max-height: 6vh;
                        }
                    }
                }            
            }




            .opcionesEscondido{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                max-width: 85vw;
                text{
                    max-width: 75vw;
                    min-width: 75vw;
                    color: #FFF;
                }
                .img{
                    display: flex;
                    min-width: 10vw;
                    max-width: 10vw;
                    img{
                        position: absolute;
                        width: 20px;
                        height: 20px;
                    }
                }
                .textContainerEscondido{
                    display: flex;
                    color: #FFF;
                    flex-direction: column;
                    margin-bottom: 1vh;
                    div{
                    }
                    .titleEscondido{
                        font-family: Montserrat;
                        font-weight: 300;
                        font-size: 1rem;
                        min-height: 0vh;
                        max-height: 0vh;
                        color: transparent;
                        animation: esconder 1s;
                    }
                    .subtitleEscondido{
                        color: transparent;
                        font-family: Montserrat;
                        font-weight: 400;
                        font-size: 1rem;
                        min-height: 0vh;
                        max-height: 0vh;
                        animation: esconderDos 1s;
                    }
                    .textEscondido{
                        font-size: 1rem;
                        min-width: 90%;
                        max-width: 90%;
                        text-align: justify;
                        color: transparent; 
                        min-height: 0vh;
                        max-height: 0vh;
                        font-family: HelveticaNeue;
                        animation: esconderTres 0.9s;
                    }


                    @keyframes esconder {
                        0%{
                            color: #fff;
                            min-height: 2vh;
                            max-height: 2vh;
                        }
                        20%{
                            color: transparent;
                        }
                        100%{                            
                            color: transparent;
                            min-height: 0vh;
                            max-height: 0vh;
                        }
                    }
                    @keyframes esconderDos {
                        0%{
                            color: #FA500F;
                            min-height: 2vh;
                            max-height: 2vh;
                        }
                        40%{
                            color: transparent;
                        }
                        100%{                          
                            color: transparent;
                            min-height: 0vh;
                            max-height: 0vh;
                        }
                    }
                    @keyframes esconderTres {
                        0%{
                            color: #C9C9C9;
                            min-height: 6vh;
                            max-height: 6vh;
                        }
                        10%{
                            color: transparent;
                        }
                        100%{
                           
                            color: transparent;
                            min-height: 0vh;
                            max-height: 0vh;
                        }
                    }
                }            
            }











            li{
                cursor: pointer;
                width: 90vw;
                list-style: none;
                display: flex;
                border-bottom:1px solid rgba(255,255,255, .3);
                text{
                    min-width: 80vw;
                    line-height: 3rem;
                    font-size: 1.2rem;
                    font-family: Montserrat;                                        
                }
                .img{
                    min-width: 10vw;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        position: absolute;
                    }
                }



                .textContainerDesplegado{
                    
                }


            }
           


        }
        
    }
}





@media (max-width: 1000px) and (orientation: landscape) {

        .containerContentPageTwo{
        min-height: 180vh;
        margin: 0;
        padding: 0;
        justify-content: flex-start;
        }
    
        .titlePageTwo{        
            padding: 0;
            margin: 0;
            max-width: 90vw;
            max-height: 15vh;
            min-height: 15vh;
            img{
                max-width: 3vw;
                margin-right: 2vw;
            }
        }
        .containerSubContentPageTwo{
            display: flex;
            align-items: center;
            .subtitleContainerPageTwo{
                font-size: 2rem;
                text-align: left;
                max-width: 90vw;
                color: #FFF;
                margin-bottom: 10vw;
                h1{
                 font-family: Montserrat;
                }
            }
        }
        
      
        .optionSelectSubtitle{
           display: none;
        }    
    
        .optionSelectSubtitleMovil{
            width: 90vw;
            display: flex;
            color: #FFF;
            margin: 0;
            padding: 0;
            ul{
                width: 90vw;
                margin: 0;
                padding: 0;
    
                .opcionesDesplegadas{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    text{
                        max-width: 80vw;
                        min-width: 80vw;
                        color: #FA500F;
                    }
                    .img{
                        min-width: 9vw;
                        max-width: 9vw;
                        img{
                            position: absolute;
                            width: 20px;
                            height: 20px;
                        }
                    }
                    .textContainerDesplegado{
                        display: flex;
                        color: #FFF;
                        flex-direction: column;
                        margin-bottom: 1vh;
                        div{
                            margin-bottom: 1vh;
                        }
                        .titleDesplegado{
                            font-family: Montserrat;
                            font-weight: 300;
                            font-size: 1rem;
                            min-height: 4vh;
                            max-height: 4vh;
                            animation: crecer 1s;
                        }
                        .subtitleDesplegado{
                            color: #FA500F;
                            font-family: Montserrat;
                            font-weight: 400;
                            font-size: 1rem;
                            min-height: 4vh;
                            max-height: 4vh;
                            animation: crecerDos 1.5s;
                        }
                        .textDesplegado{
                            font-size: 1rem;
                            min-width: 90%;
                            max-width: 90%;
                            text-align: justify;
                            color: #CBCBCB;
                            min-height: 8vh;
                            max-height: 8vh;
                            font-family: HelveticaNeue;
                            animation: crecerTres 0.9s;
                        }
    
    
                        @keyframes crecer {
                            0%{
                                color: transparent;
                                min-height: 0vh;
                                max-height: 0vh;
                            }
                            80%{
                                color: transparent;
                            }
                            100%{
                                color: #fff;
                                min-height: 4vh;
                                max-height: 4vh;
                            }
                        }
                        @keyframes crecerDos {
                            0%{
                                color: transparent;
                                min-height: 0vh;
                                max-height: 0vh;
                            }
                            50%{
                                color: transparent;
                            }
                            100%{
                                color: #FA500F;
                                min-height: 4vh;
                                max-height: 4vh;
                            }
                        }
                        @keyframes crecerTres {
                            0%{
                                color: transparent;
                                min-height: 0vh;
                                max-height: 0vh;
                            }
                            90%{
                                color: transparent;
                            }
                            100%{
                                color: #C9C9C9;
                                min-height: 8vh;
                                max-height: 8vh;
                            }
                        }
                    }            
                }
    
    
    
    
                .opcionesEscondido{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    text{
                        max-width: 80vw;
                        min-width: 80vw;
                        color: #FFF;
                    }
                    .img{
                        min-width: 9vw;
                        max-width: 9vw;
                        img{
                            position: absolute;
                            width: 20px;
                            height: 20px;
                        }
                    }
                    .textContainerEscondido{
                        display: flex;
                        color: #FFF;
                        flex-direction: column;
                        margin-bottom: 1vh;
                        div{
                        }
                        .titleEscondido{
                            font-family: Montserrat;
                            font-weight: 300;
                            font-size: 1rem;
                            min-height: 0vh;
                            max-height: 0vh;
                            color: transparent;
                            animation: esconder 1s;
                        }
                        .subtitleEscondido{
                            color: transparent;
                            font-family: Montserrat;
                            font-weight: 400;
                            font-size: 1rem;
                            min-height: 0vh;
                            max-height: 0vh;
                            animation: esconderDos 1s;
                        }
                        .textEscondido{
                            font-size: 1rem;
                            min-width: 90%;
                            max-width: 90%;
                            text-align: justify;
                            color: transparent; 
                            min-height: 0vh;
                            max-height: 0vh;
                            font-family: HelveticaNeue;
                            animation: esconderTres 0.9s;
                        }
    
    
                        @keyframes esconder {
                            0%{
                                color: #fff;
                                min-height: 2vh;
                                max-height: 2vh;
                            }
                            20%{
                                color: transparent;
                            }
                            100%{                            
                                color: transparent;
                                min-height: 0vh;
                                max-height: 0vh;
                            }
                        }
                        @keyframes esconderDos {
                            0%{
                                color: #FA500F;
                                min-height: 2vh;
                                max-height: 2vh;
                            }
                            40%{
                                color: transparent;
                            }
                            100%{                          
                                color: transparent;
                                min-height: 0vh;
                                max-height: 0vh;
                            }
                        }
                        @keyframes esconderTres {
                            0%{
                                color: #C9C9C9;
                                min-height: 6vh;
                                max-height: 6vh;
                            }
                            10%{
                                color: transparent;
                            }
                            100%{
                               
                                color: transparent;
                                min-height: 0vh;
                                max-height: 0vh;
                            }
                        }
                    }            
                }
    
    
    
    
    
    
    
    
    
    
    
                li{
                    cursor: pointer;
                    width: 90vw;
                    list-style: none;
                    display: flex;
                    border-bottom:1px solid rgba(255,255,255, .3);
                    text{
                        min-width: 80vw;
                        line-height: 3rem;
                        font-size: 1.2rem;
                        font-family: Montserrat;                                        
                    }
                    .img{
                        min-width: 10vw;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img{
                            position: absolute;
                        }
                    }
    
    
    
                    .textContainerDesplegado{
                        
                    }
    
    
                }
               
    
    
            }
            
        }
    

}












@media (max-width: 375px) and (orientation:portrait) {

    .containerContentPageTwo{
    min-height: 70vh;
    margin-bottom: 10vh;
    }
    
    .titlePageTwo{        
        max-width: 85vw;
        max-height: 15vh;
        min-height: 15vh;
        img{
            max-width: 3vw;
            margin-right: 2vw;
        }
    }
    .containerSubContentPageTwo{
        display: flex;
        align-items: center;
        .subtitleContainerPageTwo{
            font-size: 2rem;
            text-align: left;

            max-width: 90vw;
            color: #FFF;
            margin-bottom: 10vw;
            h1{
             font-family: Montserrat;
            }
        }
    }
    
  
    .optionSelectSubtitle{
       display: none;
    }



    .optionSelectSubtitleMovil{
        width: 85vw;
        display: flex;
        color: #FFF;
        margin: 0;
        padding: 0;
        margin-bottom: 18vh;
        ul{
            width: 85vw;
            margin: 0;
            padding: 0;
            .opcionesDesplegadas{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding-bottom: 6vh;
                text{
                    max-width: 75vw;
                    min-width: 75vw;
                    color: #FA500F;

                }
                .img{
                    min-width: 9vw;
                    max-width: 9vw;
                    img{
                        position: absolute;
                        width: 20px;
                        height: 20px;
                    }
                }
                .textContainerDesplegado{
                    display: flex;
                    color: #FFF;
                    flex-direction: column;
                    div{
                        margin-bottom: 1vh;
                    }
                    .titleDesplegado{
                        font-family: Montserrat;
                        font-weight: 300;
                        font-size: 1rem;
                        min-height: 2vh;
                        max-height: 2vh;
                        animation: crecer 0.5s;
                    }
                    .subtitleDesplegado{
                        color: #FA500F;
                        font-family: Montserrat;
                        font-weight: 400;
                        font-size: 1rem;
                        min-height: 2vh;
                        max-height: 2vh;
                        animation: crecerDos 1s;
                    }
                    .textDesplegado{
                        font-size: 1rem;
                        min-width: 90%;
                        max-width: 90%;
                        text-align: justify;
                        color: #CBCBCB;
                        min-height: 6vh;
                        max-height: 6vh;
                        font-family: HelveticaNeue;
                        animation: crecerTres 0.6s;
                    }


                    @keyframes crecer {
                        0%{
                            color: transparent;
                            min-height: 0vh;
                            max-height: 0vh;
                        }
                        80%{
                            color: transparent;
                        }
                        100%{
                            color: #fff;
                            min-height: 2vh;
                            max-height: 2vh;
                        }
                    }
                    @keyframes crecerDos {
                        0%{
                            color: transparent;
                            min-height: 0vh;
                            max-height: 0vh;
                        }
                        50%{
                            color: transparent;
                        }
                        100%{
                            color: #FA500F;
                            min-height: 2vh;
                            max-height: 2vh;
                        }
                    }
                    @keyframes crecerTres {
                        0%{
                            color: transparent;
                            min-height: 0vh;
                            max-height: 0vh;
                        }
                        90%{
                            color: transparent;
                        }
                        100%{
                            color: #C9C9C9;
                            min-height: 6vh;
                            max-height: 6vh;
                        }
                    }
                }            
            }




            .opcionesEscondido{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                text{
                    max-width: 75vw;
                    min-width: 75vw;
                    color: #FFF;
                }
                .img{
                    min-width: 9vw;
                    max-width: 9vw;
                    img{
                        position: absolute;
                        width: 20px;
                        height: 20px;
                    }
                }
                .textContainerEscondido{
                    display: flex;
                    color: #FFF;
                    flex-direction: column;
                    margin-bottom: 1vh;
                    div{
                    }
                    .titleEscondido{
                        font-family: Montserrat;
                        font-weight: 300;
                        font-size: 1rem;
                        min-height: 0vh;
                        max-height: 0vh;
                        color: transparent;
                        animation: esconder 1s;
                    }
                    .subtitleEscondido{
                        color: transparent;
                        font-family: Montserrat;
                        font-weight: 400;
                        font-size: 1rem;
                        min-height: 0vh;
                        max-height: 0vh;
                        animation: esconderDos 1s;
                    }
                    .textEscondido{
                        font-size: 1rem;
                        min-width: 90%;
                        max-width: 90%;
                        text-align: justify;
                        color: transparent; 
                        min-height: 0vh;
                        max-height: 0vh;
                        font-family: HelveticaNeue;
                        animation: esconderTres 0.9s;
                    }


                    @keyframes esconder {
                        0%{
                            color: #fff;
                            min-height: 2vh;
                            max-height: 2vh;
                        }
                        20%{
                            color: transparent;
                        }
                        100%{                            
                            color: transparent;
                            min-height: 0vh;
                            max-height: 0vh;
                        }
                    }
                    @keyframes esconderDos {
                        0%{
                            color: #FA500F;
                            min-height: 2vh;
                            max-height: 2vh;
                        }
                        40%{
                            color: transparent;
                        }
                        100%{                          
                            color: transparent;
                            min-height: 0vh;
                            max-height: 0vh;
                        }
                    }
                    @keyframes esconderTres {
                        0%{
                            color: #C9C9C9;
                            min-height: 6vh;
                            max-height: 6vh;
                        }
                        10%{
                            color: transparent;
                        }
                        100%{
                           
                            color: transparent;
                            min-height: 0vh;
                            max-height: 0vh;
                        }
                    }
                }            
            }

            li{
                cursor: pointer;
                width: 90vw;
                list-style: none;
                display: flex;
                border-bottom:1px solid rgba(255,255,255, .3);
                text{
                    min-width: 80vw;
                    line-height: 3rem;
                    font-size: 1.2rem;
                    font-family: Montserrat;                                        
                }
                .img{
                    min-width: 10vw;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        position: absolute;
                    }
                }



                .textContainerDesplegado{
                    min-height: 20vh;
                    max-height: 20vh;
                    
                }


            }
           


        }
        
    }
}












