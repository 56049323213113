@media (max-width: 1180px)and(orientation: landscape) {


    @mixin white-gradient {
        //background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 20%);
    }
    
    
    
    $animationSpeedFive: 20s;
    
    // Animation
    @keyframes scrollFive {
        0% { transform: translateX(0); }
        100% { transform: translateX(calc(-35vw * 9))}
    }
    
    
    // Styling
    .sliderFive {
        background-color: none;
        min-width: 100vw;
        max-width: 100vw;
        
        min-height: 60vh;
        max-height: 60vh;
        overflow:hidden;
        overflow-x: hidden;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        &::before,
        &::after {
            @include white-gradient;
            content: "";
            height: 100%;
            width: 10px;
            z-index: 2;
        }
        
        &::after {
            right: 0;
            top: 0;
            transform: rotateZ(180deg);
        }
    
        &::before {
            left: 0;
            top: 0;
        }
        
        .slide-trackFive {
            animation: scrollFive $animationSpeedFive linear infinite;
            display: flex;
            width: calc(35vw * 18);
        }
        .slide-trackFive:hover {
            animation-play-state: paused;
        }
        
        .slideFive {
            min-height: 60vh;
            min-width: 35vw;
            max-width: 35vw;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            display: flex;
            justify-content: center;
            align-items: center;
            div{
                min-width: 80%;
                min-height: 100%;
                background-size: 100%;
                background-repeat: no-repeat;		
            }
    
    
    
            .texxto{
                min-width: 10vw;
                z-index: 20;
                margin-left: 0vw;
                margin-top: 62vh;
                position: absolute;
                .textNameTeam{	
                    display: flex;
                    flex-direction: column;
                    font-family: Montserrat;
                    .Name{
                        color: #FF4700;;
                        text{
                            color: white;
                        }
                        margin-bottom: 15px;
                    }
                    .position{
                        padding-top: 15px;
                        border-top: 1px solid rgba(255,255,255, .3);
                        color: #939393;
                    }
                }
            }	
        }
        .containerContentPageFiveContentPrincipalFooter{
            margin-top: 3vh;
        }
        .imagenCreate{
            max-width: 80vw;
            margin-left: 10vw;
            
        }
        
    }
    
}






@media (max-width: 850px ) and  (orientation:portrait) {

    @mixin white-gradient {
        //background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 20%);
    }        
    $animationSpeedFive: 20s;
    
    // Animation
    @keyframes scrollFive {
        0% { transform: translateX(0); }
        100% { transform: translateX(calc(-35vw * 9))}
    }
    
    
    // Styling
    .sliderFive {
        background-color: none;
        min-width: 100vw;
        max-width: 100vw;
        
        min-height: 60vh;
        max-height: 60vh;
        overflow:hidden;
        overflow-x: hidden;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        &::before,
        &::after {
            @include white-gradient;
            content: "";
            height: 100%;
            width: 10px;
            z-index: 2;
        }
        
        &::after {
            right: 0;
            top: 0;
            transform: rotateZ(180deg);
        }
    
        &::before {
            left: 0;
            top: 0;
        }
        
        .slide-trackFive {
            animation: scrollFive $animationSpeedFive linear infinite;
            display: flex;
            width: calc(35vw * 18);
        }
        .slide-trackFive:hover {
            animation-play-state: paused;
        }
        
        .slideFive {
            min-height: 60vh;
            min-width: 35vw;
            max-width: 35vw;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            display: flex;
            justify-content: center;
            align-items: center;
            div{
                min-width: 80%;
                min-height: 100%;
                background-size: 100%;
                background-repeat: no-repeat;		
            }
    
    
    
            .texxto{ 
                
                min-width: 10vw;
                z-index: 20;
                margin-left: 0vw;
                margin-top: 50vh;
                position: absolute;
                .textNameTeam{	
                    display: flex;
                    flex-direction: column;
                    font-family: Montserrat;
                    .Name{
                        color: #FF4700;;
                        text{
                            color: white;
                        }
                        margin-bottom: 15px;
                    }
                    .position{
                        padding-top: 15px;
                        border-top: 1px solid rgba(255,255,255, .3);
                        color: #939393;
                    }
                }
            }	
        }
       
        
    }
    .containerContentPageFiveContentPrincipalFooter{
          
        margin-top: 3vh;
    }
    .imagenCreate{
        max-width: 40vw;
        margin-left: 1vw;
        
    }
}

