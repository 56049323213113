




    .carruselContainerTeam{
        min-height: 50vh;
        min-width: 100vw;
        background-color: #000;
    
        display: flex;
        justify-content: center;
        align-items: center;
    
    
    
        .slideTeamCarrusel{
            display: flex;
    
            min-width: 100vw;
            max-width: 100vw;
            overflow-x: auto;
            min-height: 40vh;
    
            position: relative;
            overflow: hidden;      
            .slider, .sliderclon{
                min-width: 50vw;
                min-height: 20vh;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 4rem;
                .containerPhoto{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-width: 300px;
                    min-height: 300px;
                    .foto{
                        min-width: 200px;
                        min-height: 200px;
                    }
                    .texto{
                        min-width: 200px;
                        min-height: 50px;
                        display: flex;
                        .textNameTeam{	
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            font-family: Montserrat;
                            font-size: 16px;
                            min-width: 200px;
                            .Name{
                                color: #FF4700;;
                                text{
                                    color: white;
                                }
                                margin-bottom: 15px;
                            }
                            .position{
                                padding-top: 15px;
                                border-top: 1px solid rgba(255,255,255, .3);
                                color: #939393;
                            }
                        }
                    }
                }
            }
            .inicio{
                animation: RecorrerSliders 20s infinite;
            }
            .uno{
                .foto{
                    background-image: url('../../img/pagefive/Ari/Ari_001.png');
                    background-size: contain;
                    animation: Uno 20s infinite;
                    font-size: 1.2rem;
                }           
                
            }        
            .dos{
                .foto{
                    background-image: url('../../img/pagefive/Carlos/Carlos_002.png');
                    background-size: contain;
                    animation: Dos 20s infinite;
                    min-width: 300px;
                    min-height: 300px;
                }
            }
            .tres{
                .foto{
                    background-image: url('../../img/pagefive/Jaime/Jaime_001.png');
                    background-size: contain;
                    animation: Tres 20s infinite;
                }
            }
            .cuatro{
                .foto{
                    background-image: url('../../img/pagefive/Bianca/Bianca_001.png');
                    background-size: contain;
                    animation: Cuatro 20s infinite;
                }
            }
            .cinco{
                .foto{
                    background-image: url('../../img/pagefive/Rafa/Rafa_001.png');
                    background-size: contain;
                    animation: Cinco 20s infinite;
                }
            }
            .seis{
                .foto{
                    background-image: url('../../img/pagefive/Brenda/Brenda1.png');
                    background-size: contain;
                    animation: Seis 20s infinite;
                }
            }
            .siete{
                .foto{
                    background-image: url('../../img/pagefive/Vladimir/Vladimir1.png');
                    background-size: contain;
                    animation: Siete 20s infinite;
                }
            }
            .ocho{
                .foto{
                    background-image: url('../../img/pagefive/CarlosC/CarlosC1.png');
                    background-size: contain;
                    animation: Ocho 20s infinite;
                }
            }
            .nueve{
                .foto{
                    background-image: url('../../img/pagefive/Eli/Eli-.png');
                    background-size: contain;
                    animation: Nueve 20s infinite;
                }
            }
            .diez{
                .foto{
                    background-image: url('../../img/pagefive/Coco/Coco.png');
                    background-size: contain;
                    animation: Diez 20s infinite;
                }
            }
        }
    }


    @keyframes Dos{
        0%{
            min-width: 200px;
            min-height: 200px;
            background-image: url('../../img/pagefive/Carlos/Carlos_002.png');                
        
        }
        10%{
            min-width: 180px;
            min-height: 180px;
            background-image: url('../../img/pagefive/Carlos/Carlos_1.png');
        
        }
        90%{
            
            min-width: 180px;
            min-height: 180px;
            background-image: url('../../img/pagefive/Carlos/Carlos_1.png');
        }    
        100%{        
            min-width: 200px;
            min-height: 200px;
            background-image: url('../../img/pagefive/Carlos/Carlos_002.png');                
        }

    }

    @keyframes Tres{
        0%{
            min-width: 180px;
            min-height: 180px;           
            background-image: url('../../img/pagefive/Jaime/Jaime_001.png');
        }
        10%{
            min-width: 200px;
            min-height: 200px;
            background-image: url('../../img/pagefive/Jaime/Jaime_002.png');
        }
        20%{
            min-width: 180px;
            min-height: 180px;   
            background-image: url('../../img/pagefive/Jaime/Jaime_001.png');
        }    
        100%{   
        }

    }

    @keyframes Cuatro{
        0%  {
        }
        10%{    
            min-width: 180px;
            min-height: 180px;
                background-image: url('../../img/pagefive/Bianca/Bianca_001.png');
        }
        20%{
            min-width: 200px;
            min-height: 200px;
            background-image: url('../../img/pagefive/Bianca/Bianca_002.png');
        }
        30%{
            min-width: 180px;
            min-height: 180px;   
            background-image: url('../../img/pagefive/Bianca/Bianca_001.png');
        }  
        100%{   
        }

    }


    @keyframes Cinco{
        0%  {
        }
        20%{
            min-width: 180px;
            min-height: 180px;
            background-image: url('../../img/pagefive/Rafa/Rafa_001.png');
        }
        30%{
            min-width: 200px;
            min-height: 200px;
            background-image: url('../../img/pagefive/Rafa/Rafa_002.png');
        }
        40%{
            min-width: 180px;
            min-height: 180px;
            background-image: url('../../img/pagefive/Rafa/Rafa_001.png');
        }    
        100%{   
        }

    }

    @keyframes Seis{
        0%  {
        }
        30%{
            min-width: 180px;
            min-height: 180px; 
            background-image: url('../../img/pagefive/Brenda/Brenda1.png');
        }
        40%{
            min-width: 200px;
            min-height: 200px;
            background-image: url('../../img/pagefive/Brenda/Brenda2.png');
        }    
        50%{
            min-width: 180px;
            background-image: url('../../img/pagefive/Brenda/Brenda1.png');
            min-height: 180px;
        }    
        100%{   
        }

    }

    @keyframes Siete{
        0%  {
        }
        40%{
            min-width: 180px;
            min-height: 180px;
            background-image: url('../../img/pagefive/Vladimir/Vladimir1.png');
        }    
        50%{
            min-width: 200px;
            min-height: 200px;
            background-image: url('../../img/pagefive/Vladimir/Vladimir2.png');
        }    
        60%{
            min-width: 180px;
            min-height: 180px; 
            background-image: url('../../img/pagefive/Vladimir/Vladimir1.png');
        }    
        100%{   
        }

    }


    @keyframes Ocho{
        0%  {
        } 
        50%{
            min-width: 180px;
            min-height: 180px;
            background-image: url('../../img/pagefive/CarlosC/CarlosC1.png');
        }    
        60%{
            min-width: 200px;
            min-height: 200px;
            background-image: url('../../img/pagefive/CarlosC/CarlosC2.png');
        }    
        70%{
            min-width: 180px;
            min-height: 180px;
            background-image: url('../../img/pagefive/CarlosC/CarlosC1.png');
        } 
        100%{   
        }

    }

    @keyframes Nueve{
        0%  {
        }
        60%{
            min-width: 180px;
            min-height: 180px;  
            background-image: url('../../img/pagefive/Eli/Eli-.png');
        }    
        70%{
            min-width: 200px;
            min-height: 200px;
            background-image: url('../../img/pagefive/Eli/Eli.png');
        } 
        80%{
            min-width: 180px;
            min-height: 180px;
            background-image: url('../../img/pagefive/Eli/Eli-.png');
        }   
        100%{   
        }

    }

    @keyframes Diez{
        0%  {
        }  
        70%{
            min-width: 180px;
            min-height: 180px;
            background-image: url('../../img/pagefive/Coco/Coco-.png');
        } 
        80%{
            min-width: 200px;
            min-height: 200px;
            background-image: url('../../img/pagefive/Coco/Coco.png');
        }       
        90%{        
            background-image: url('../../img/pagefive/Coco/Coco-.png');
            min-width: 180px;
            min-height: 180px;   
        }    
        100%{   
        }

    }

    @keyframes Uno{
        0%  {
        }
        80%{
            min-width: 180px;
            min-height: 180px;
            background-image: url('../../img/pagefive/Ari/Ari_001.png');
        }       
        90%{        
            background-image: url('../../img/pagefive/Ari/Ari_002.png');
            min-width: 200px;
            min-height: 200px;
        }    
        100%{   
            background-image: url('../../img/pagefive/Ari/Ari_001.png');
            min-width: 200px;
            min-height: 200px;        
        }

    }

    @keyframes RecorrerSliders{
        0%{
        }
        10%{
            margin-left: -50vw;        
        }
        20%{
            margin-left: -100vw;
        }
        30%{
            margin-left: -150vw;
        }
        40%{
            margin-left: -200vw;
        }    
        50%{
            margin-left: -250vw;
        }    
        60%{
            margin-left: -300vw;
        }    
        70%{
            margin-left: -350vw; 
        } 
        80%{
            margin-left: -400vw;
        }       
        90%{
            margin-left: -450vw;
        }    
        100%{
            margin-left: -500vw;
        }
    }











// ------------- DESKTOP  ------------






    @media  (min-width:800px) and (min-height:600px) {
        .carruselContainerTeam{
            min-height: 50vh;
            min-width: 100vw;
            background-color: #000;
        
            display: flex;
            justify-content: center;
            align-items: center;
                
            .slideTeamCarrusel{
                display: flex;
        
                min-width: 100vw;
                max-width: 100vw;
                overflow-x: auto;
                min-height: 40vh;
                position: relative;
                overflow: hidden;      
                .slider, .sliderclon{
                    min-width: 33.33vw;
                    min-height: 20vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 4rem;
                    .containerPhoto{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        min-width: 300px;
                        min-height: 300px;


                        .foto{
                            min-width: 200px;
                            min-height: 200px;                    
                        }
                        .texto{
                            min-width: 200px;
                            min-height: 50px;
                            display: flex;
                            .textNameTeam{	
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                font-family: Montserrat;
                                font-size: 16px;
                                min-width: 200px;
                                .Name{
                                    color: #FF4700;;
                                    text{
                                        color: white;
                                    }
                                    margin-bottom: 15px;
                                }
                                .position{
                                    padding-top: 15px;
                                    border-top: 1px solid rgba(255,255,255, .3);
                                    color: #939393;
                                }
                            }
                        }
                    }
                }
                .inicio{
                    animation: RecorrerSliders 20s infinite;
                }
                .uno{
                    .foto{
                        background-image: url('../../img/pagefive/Ari/Ari_001.png');         
                        background-size: contain;
                        animation: Uno 20s infinite;
                        font-size: 1.2rem;
                    }           
                    
                }        
                .dos{
                    .foto{
                        background-image: url('../../img/pagefive/Carlos/Carlos_002.png');
                        background-size: contain;
                        animation: Dos 20s infinite;
                        min-width: 300px;
                        min-height: 300px;
                    }
                }
                .tres{
                    .foto{
                        background-image: url('../../img/pagefive/Jaime/Jaime_001.png');
                        background-size: contain;
                        animation: Tres 20s infinite;
                    }
                }
                .cuatro{
                    .foto{
                        background-image: url('../../img/pagefive/Bianca/Bianca_001.png');
                        background-size: contain;
                        animation: Cuatro 20s infinite;
                    }
                }
                .cinco{
                    .foto{
                        background-image: url('../../img/pagefive/Rafa/Rafa_001.png');
                        background-size: contain;
                        animation: Cinco 20s infinite;
                    }
                }
                .seis{
                    .foto{
                        background-image: url('../../img/pagefive/Brenda/Brenda1.png');
                        background-size: contain;
                        animation: Seis 20s infinite;
                    }
                }
                .siete{
                    .foto{
                        background-image: url('../../img/pagefive/Vladimir/Vladimir1.png');
                        background-size: contain;
                        animation: Siete 20s infinite;
                    }
                }
                .ocho{
                    .foto{
                        background-image: url('../../img/pagefive/CarlosC/CarlosC1.png');
                        background-size: contain;
                        animation: Ocho 20s infinite;
                    }
                }
                .nueve{
                    .foto{
                        background-image: url('../../img/pagefive/Eli/Eli-.png');
                        background-size: contain;
                        animation: Nueve 20s infinite;
                    }
                }
                .diez{
                    .foto{
                        background-image: url('../../img/pagefive/Coco/Coco-.png');
                        background-size: contain;
                        animation: Diez 20s infinite;
                    }
                }
            }
        }
        
        
        
        @keyframes Dos{
            0%{
                min-width: 350px;
                min-height: 350px;
                background-image: url('../../img/pagefive/Carlos/Carlos_002.png');                
            
            }
            10%{
                min-width: 220px;
                min-height: 220px;
                background-image: url('../../img/pagefive/Carlos/Carlos_1.png');
             
            }
            90%{
                 
                min-width: 220px;
                min-height: 220px;
                background-image: url('../../img/pagefive/Carlos/Carlos_1.png');
            }    
            100%{        
                min-width: 350px;
                min-height: 350px;
                background-image: url('../../img/pagefive/Carlos/Carlos_002.png');                
            }
        
        }
        
        @keyframes Tres{
            0%{
                min-width: 220px;
                min-height: 220px;       
                background-image: url('../../img/pagefive/Jaime/Jaime_001.png');
            }
            10%{
                min-width: 350px;
                min-height: 350px;
                background-image: url('../../img/pagefive/Jaime/Jaime_002.png');
            }
            30%{
                min-width: 220px;
                min-height: 220px;
                background-image: url('../../img/pagefive/Jaime/Jaime_001.png');
            }    
            100%{   
            }
        
        }
        
        @keyframes Cuatro{
            0%  {
            }
            10%{    
                min-width: 220px;
                min-height: 220px;
                    background-image: url('../../img/pagefive/Bianca/Bianca_001.png');
            }
            20%{
                min-width: 350px;
                min-height: 350px;
                background-image: url('../../img/pagefive/Bianca/Bianca_002.png');
            }
            30%{
                min-width: 220px;
                min-height: 220px;
                background-image: url('../../img/pagefive/Bianca/Bianca_001.png');
            }  
            100%{   
            }
        
        }
        
        
        @keyframes Cinco{
            0%  {
            }
            20%{
                min-width: 220px;
                min-height: 220px;
                background-image: url('../../img/pagefive/Rafa/Rafa_001.png');
            }
            30%{
                min-width: 350px;
                min-height: 350px;
                background-image: url('../../img/pagefive/Rafa/Rafa_002.png');
            }
            40%{
                min-width: 220px;
                min-height: 220px;
                background-image: url('../../img/pagefive/Rafa/Rafa_001.png');
            }    
            100%{   
            }
        
        }
        
        @keyframes Seis{
            0%  {
            }
            30%{
                min-width: 220px;
                min-height: 220px;
                background-image: url('../../img/pagefive/Brenda/Brenda1.png');
            }
            40%{
                min-width: 350px;
                min-height: 350px;
                background-image: url('../../img/pagefive/Brenda/Brenda2.png');
            }    
            50%{
                min-width: 220px;
                min-height: 220px;
                background-image: url('../../img/pagefive/Brenda/Brenda1.png');
            
            }    
            100%{   
            }
        
        }
        
        @keyframes Siete{
            0%  {
            }
            40%{
                min-width: 220px;
                min-height: 220px;
                background-image: url('../../img/pagefive/Vladimir/Vladimir1.png');
            }    
            50%{
                min-width: 350px;
                min-height: 350px;
                background-image: url('../../img/pagefive/Vladimir/Vladimir2.png');
            }    
            60%{
                min-width: 220px;
                min-height: 220px;
                background-image: url('../../img/pagefive/Vladimir/Vladimir1.png');
            }    
            100%{   
            }
        
        }
        
        
        @keyframes Ocho{
            0%  {
            } 
            50%{
                min-width: 220px;
                min-height: 220px;
                background-image: url('../../img/pagefive/CarlosC/CarlosC1.png');
            }    
            60%{
                min-width: 350px;
                min-height: 350px;
                background-image: url('../../img/pagefive/CarlosC/CarlosC2.png');
            }    
            70%{
                min-width: 220px;
                min-height: 220px;
                background-image: url('../../img/pagefive/CarlosC/CarlosC1.png');
            } 
            100%{   
            }
        
        }
          
        @keyframes Nueve{
            0%  {
            }
            60%{
                min-width: 220px;
                min-height: 220px;
                background-image: url('../../img/pagefive/Eli/Eli-.png');
            }    
            70%{
                min-width: 350px;
                min-height: 350px;
                background-image: url('../../img/pagefive/Eli/Eli.png');
            } 
            80%{
                min-width: 220px;
                min-height: 220px;
                background-image: url('../../img/pagefive/Eli/Eli-.png');
            }   
            100%{   
            }
        
        }
        
      
        @keyframes Diez{
            0%  {
            }  
            70%{
                min-width: 220px;
                min-height: 220px;
                background-image: url('../../img/pagefive/Coco/Coco-.png');
            } 
            80%{
                min-width: 350px;
                min-height: 350px;
                background-image: url('../../img/pagefive/Coco/Coco.png');
            }       
            90%{        
                background-image: url('../../img/pagefive/Coco/Coco-.png');
                min-width: 220px;
                min-height: 220px;
            }    
            100%{   
            }        
        }
        
        
        @keyframes Uno{
            0%  {
            }
            80%{
                min-width: 220px;
                min-height: 220px;
                background-image: url('../../img/pagefive/Ari/Ari_001.png');
            
            }       
            90%{        
                min-width: 350px;
                min-height: 350px;
                background-image: url('../../img/pagefive/Ari/Ari_002.png');
            }    
            100%{   
                min-width: 220px;
                min-height: 220px;
                background-image: url('../../img/pagefive/Ari/Ari_001.png');
            }
        
        }
        
            @keyframes RecorrerSliders{

                0%{
                }
                10%{
                    margin-left: -33.33vw;        
                }
                20%{
                    margin-left: -66.66vw;
                }
                30%{
                    margin-left: -100vw;
                }
                40%{
                    margin-left: -133.33vw;
                } 
                50%{
                    margin-left: -167vw;
                }  
                60%{
                    margin-left: -200vw;
                }   
                70%{
                    margin-left: -233.3vw;
                }    
                80%{
                    margin-left: -267vw;
                }    
                90%{
                    margin-left: -300vw; 
                } 
                100%{
                    margin-left: -333.3vw;
                }                                  
            }
        
        }
        