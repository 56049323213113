@media (max-width: 1000px) and (orientation: landscape) {

    .titleRightPageSix{
        visibility: hidden;
    }

	.containerContentPageSix{
		min-width: 100vw;
		min-height: 100vh;
		max-height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	

	.contentcontainerPageSix{
$animationSpeed: 20s;

// Animation
@keyframes scrollAdelante {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(+80vw * 6))}
}


// Styling
.sliderTwo {
	
	&::after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

	&::before {
		left: 0;
		top: 0;
	}
	
	.slide-track {
		animation: scrollAdelante $animationSpeed linear infinite;
		display: flex;
		width: calc(80vw * 12);
	}
	
	.slide {
		min-width: 80vw;
		.contentOne, .contentTwo{
			min-width: 80vw;
		}
			
		.contentOne{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			min-height: 100%;
			.contentOneLeft{
				margin: 0;
				min-width: 20vw;
				display: flex;
				justify-content: center;
				align-items: center;
				.Foto1,.Foto2,.Foto3,.Foto4,.Foto5,.Foto6{
					min-width: 20vw;
				}				
			}
			.contentOneRight{
				min-width: 40vw;
				min-height: 60%;
				padding-top: 0;
				margin: 0;
				display: flex;
				text{					
					padding: 0;
					margin: 0;
					display: flex;
					justify-content: flex-start;
					align-items: flex-start;
					.Title{
						margin: 0;
						min-width: 100%;
						margin-bottom: 10px;
						font-size: 2rem;
						text-align: center;
					}
					.Content{
						min-width: 100%;
						margin-bottom: 10px;
						font-size: 1rem;
						line-height: 1rem;
						text-align: left;
					}
					.Client{
						min-width: 100%;
						margin-bottom: 10px;
						font-size: 0.9rem;
						line-height: 1rem;
					}

				}
			}
		}
	



		.contentTwo{
			display: flex;
			flex-direction: column-reverse;

			.contentTwoLeft{
				min-width: 40vw;
				min-height: 60%;					
				text{					
					.Title{

						min-width: 100%;
						margin-bottom: 10px;
						font-size: 1.2rem;
					}
					.Content{
						min-width: 100%;
						margin-bottom: 10px;
						font-size: 1rem;
						line-height: 1rem;
					}
					.Client{
						min-width: 100%;
						margin-bottom: 10px;
						font-size: 0.9rem;
						line-height: 1rem;
					}

				}
			}
			.contentTwoRight{
				min-width: 40vw;
				min-height: 20%;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0;
				padding: 0;
				border: 1px solid yellow;
				.Foto1,.Foto2,.Foto3,.Foto4,.Foto5,.Foto6{
					margin: 0;
					min-width: 40vw;
					min-height: 20vw;
				}
			}
		}

	}
}
}





}