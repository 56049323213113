

@media (max-width:700px){
    .containerInformation{
       
        .titleInformation{
         
            img{
                width: 70%;
            }
        }
    }
    
    .bottonend{
        min-height: 20vw;
        height: 100%;
        min-width: 90vw;
        .designByBlack{
            min-width: 50vw;
            margin-left: 40vw;
       
        }
    }
}










@media (max-width:390px){
    .containerInformation{
        .titleInformation{
         
            img{
                width: 70%;
            }
        }



        .informationBotton{
            width: 100%;
            height: 50%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
        
    
            .textTwo{
                text{
                }
            }
            .textThree{
                min-width: 95vw;
                font-size: 0.85rem;
            }
        }
        

    }
    
    .bottonend{
        min-height: 20vw;
        height: 100%;
        min-width: 90vw;
        .designByBlack{
            min-width: 50vw;
            margin-left: 40vw;
       
        }
    }
}


@media (max-width: 1000px) and (orientation: landscape) {


    .containerContentPageSeven{
        min-width: 100vw;
        min-height: 120vh;
        max-height: 120vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }
    .containerInformation{
        width: 90vw;
        height: 52vh;
        .titleInformation{
            width: 100%;
            min-height: 30%;
            max-height: 30%;
            display: flex;
            justify-content: center;
            img{
                width: 20%;
            }
        }
        .informationBotton{
            width: 100%;
            min-height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
                .textTwo{
                    color: #FFF;
                    font-size: 1.8rem;
                    font-family: Montserrat;
                    margin-bottom: 20%;
                text{
                    font-family: Montserrat;
                    font-size: 1rem;
                }
            }
            .iconsBottoms{
                justify-content: space-around;
                min-height: 10vh;
                padding-left: 2.5vw;
                a{
                max-width: 10vw;
                min-width: 10vw;
                display: flex;
                justify-content: flex-start;
                }
            }
            .textThree{
               
                font-size: 0.9rem;
                font-family: Montserrat;
                margin-bottom: 2%;
                margin-top: 2%;
                color: #FFF;
                text{
                    color: #FFF;
                    font-family: Montserrat;
                    font-size: 1rem;
                    text-align: center;
                }
            }
        }
        
    }
    
    .bottonend{
        height: 100%;
        min-width: 90vw;
        .designBy{
            display: flex;
            justify-content: center;
            min-height: 10vh;
            min-width: 20vw;
            margin-left: 70vw;
            .st1{
                fill: #FFF;
            }
            .st2{
                fill: #FFF;
            }
        }
    }


}