.containerContentPageFive{
    min-width: 100vw;
    max-width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}


.containerContentPageFiveContentPrincipal{
  min-width: 100vw;
  min-width: 100vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.containerContentPageFiveContentPrincipalHeader{
  max-width: 90vw;
  min-width: 90vw;
  height: 10vh;
margin: 0;
  color: #FFF;
 display: flex;
 justify-content: flex-start;
 align-items: flex-start;
  font-family: Montserrat;
  .textOne{
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    line-height: 1.5rem;
    font-size: 2.5rem;
    font-weight: 700;
    margin-right: 1%;
   
  }
  .textTwo{
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    line-height: 5rem;
    color: #FF4700;
    font-size: 7rem;
    font-weight: 700;
  }
}

.containerContentPageFiveContentPrincipalCentral{
  margin-top: 5vh; 
  min-height: 60vh;
  max-width: 100%;
  max-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
}

.containerContentPageFiveContentPrincipalFooter{
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
   font-family: Montserrat;
   .textOne{
     width: 50%;
     height: 80%;
     display: flex;
     justify-content: flex-end;
     align-items: flex-end;
     font-size: 2rem;
     color: #FF4700;
   }
   .textTwo{
    width: 50%;
    height: 100%;
    color: #FFF;
    display: flex;
    justify-content: flex-start;
    align-items: center;  
    margin-top: 0.3%;
    .imagen{
      margin-left: 1vw;
       max-width: 38%;
       display: flex;
       justify-content: center;
       align-items: center;

    }
   }
}





















@media (max-width: 700px) {
    .containerContentPageFive{
      width: 100vw;
      min-height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .containerContentPageFiveContentPrincipal{
    min-width: 85vw;
    max-width: 85vw;
    display: flex;
    
  }
  .containerContentPageFiveContentPrincipalHeader{
    
    .textOne{
      margin-left: 3vw;
      font-size: 1.5rem;
    }
    .textTwo{
      line-height: 4.5rem;
      font-size: 5.5rem;
    }
  }

 
  

  .containerContentPageFiveContentPrincipalFooter{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .textOne{
      text-align: center;
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 1.8rem;
    }
    .textTwo{
      display: flex;
      justify-content: center;
      font-size: 4.2rem;
      font-weight: 500;
      width: 100%;
      margin: 0;
      .imagen{
        margin: 0;
        max-width: 70%;
     }
    }
  }
}









@media (max-width: 390) {
  .containerContentPageFive{
    width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid red;
}
.containerContentPageFiveContentPrincipal{
  border: 1px solid red;

}
.containerContentPageFiveContentPrincipalHeader{
  .textOne{
    font-size: 1.5rem;
  }
  .textTwo{
    line-height: 4.5rem;
    font-size: 5.5rem;
  }
}


.containerContentPageFiveContentPrincipalFooter{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .textOne{
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 1.8rem;
  }
  .textTwo{
    display: flex;
    justify-content: center;
    font-size: 4.2rem;
    font-weight: 500;
    width: 100%;
    margin: 0;
    .imagen{
      margin: 0;
      max-width: 70%;
   }
  }
}
}