

@media (max-width: 700px) {
    .containerContentPageThree{
        min-height: 40vh;
        margin-bottom: 0;
    }
    
    .pageThreeContent{
        min-height: 40vh;
        min-width: 85vw;
        max-width: 85vw;
        .containerTitleContentPageThree{
            min-width: 85vw;
            max-width: 85vw;
            .containerTitleContentPageThreeLeft{
               
                img{
                    max-width: 3vw;
                    min-width: 3vw;
                    margin-right: 2vw;
                }
            }
            .containerTitleContentPageThreeRight{
              display: none;
                .selectText{
                }
            }
        }
    
        .submenuContainerPageThree{
            .submenuLeftContainer{
                display: none;
                .submenuLeftContainerIcons{

                    display: none;
                    img{
                    }                
                }
                .submenuLeftContainerBottom{
                    display: none;
                    .submenuRightContainerHeader{
                    }
                    .submenuRightContainerBottom{
                       
                        .submenuRightContainerBottomLeft{
                            .branding{
                    
                            }
                            .Design{
                           
                                
                            }
                            .Web{
                
                            }
                        }   
                        .submenuRightContainerBottomRight{
                        }
                    }
                }
            }
            margin: 0;
            padding: 0;
            max-width: 85vw;
            min-width: 85vw;
            .submenuRightContainer{
                margin-left: 0;
                margin: 0;
                min-width: 85vw;
                padding: 0;
                display: flex;

                justify-content: left;               
                ul{            
                min-width: 100%;
                
                padding: 0;

                li{
                    font-size: 1rem;
                    animation: none;

                  
                     
                    .title{                        
                        font-size: 1rem;
                        text {
                            font-size: 1.2rem;
                        } 
                        .opcion{                  
                            animation: none;      
                            .menos{
                                animation: none;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                


                            }
                            .mas{
                                position: absolute;                               
                                background-color: #C9C9C9;
                            }
                        }
                        
                    }   
                    
                }   
                li:hover{
                    animation: none;
                }
                .selected{
                    animation: none;
                    font-size: 1rem;

                           
                    .titleselect{
                        font-size: 1rem;
                        text {
                            font-size: 1.2rem;
                        } 
                        .opcion{
                           
                        }
                       
                    }   
                            
                    ul{
                    margin: 0;
                    padding: 0;
                    border-bottom: 1.5px solid #FFF;
                    padding-bottom: 2vh;
                    margin-top: -1vh;
                        li{
                            
                            background-color: #000;
                            margin: 0px;
                            padding: 0;                        
                        }
                    }                    
                   
                }          
               }
            }
        }
    }
    
    

}



@media (max-width: 1000px) and (orientation: landscape) {


    .containerContentPageThree{
        min-height: 80vh;
        margin-bottom: 0;
    }
    
    .pageThreeContent{
        min-height: 40vh;
        min-width: 85vw;
        max-width: 85vw;
        .containerTitleContentPageThree{
            min-width: 85vw;
            max-width: 85vw;
            .containerTitleContentPageThreeLeft{
                border: 1px solid red;
                img{
                    max-width: 1.5vw;
                    min-width: 1.5vw;
                    margin-right: 2vw;
                }
            }
            .containerTitleContentPageThreeRight{
              display: none;
              border: 1px solid yellow;
                .selectText{
                }
            }
        }
    
        .submenuContainerPageThree{
      
            .submenuLeftContainer{
            
                .submenuLeftContainerIcons{
                    display: none;
                    img{
                
                    }                
                }
                .submenuLeftContainerBottom{
                    display: none;
                    .submenuRightContainerHeader{
                    }
                    .submenuRightContainerBottom{
                        
                        .submenuRightContainerBottomLeft{
         
                            .branding{
                    
                            }
                            .Design{
                           
                                
                            }
                            .Web{
                
                            }
                        }   
                        .submenuRightContainerBottomRight{
     
                        }
                    }
                }
            }
            margin: 0;
            padding: 0;
            max-width: 85vw;
            min-width: 85vw;
            .submenuRightContainer{
                margin-left: 0;
                margin: 0;
                min-width: 85vw;
                padding: 0;
               ul{            
                min-width: 100%;
                li{
                    font-size: 1rem;
                    .title{
                        font-size: 1rem;
                        text {
                            font-size: 1.2rem;
                        } 
                        .opcion{
                    
    
                            .menos{
                                //min-height: 30px;
                                //max-height: 30px;
                                //border: 1px solid #FFF;
                                //background-color: #FFF;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                            .mas{
                                //border: 1px solid red;
                                position: absolute;
                            }
                        }
                        
                    }   
                    
                }   
     
                .selected{
                    font-size: 1rem;
                    .titleselect{
                        font-size: 1rem;
                        text {
                            font-size: 1.2rem;
                        } 
                        .opcion{
                       
                        }
                       
                    }   
                            
                    ul{
                     
                        li{
                        }
                    }                    
                   
                }          
               }
            }
        }
    }
    
    
}





