.ContentProjects{
    border-top: 1px solid rgba(0,0,0, .3);
    width: 90vw;
    margin-top: 15vh;
    min-height: 100vh;
    margin-bottom:10vh;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    .ContentProjectsTitle{
        width: 90vw;
        min-height: 15vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        padding-top: 2vh;
        div{
            width: 50%;
        }
        .ContentProjectsLeft{
            font-family: Montserrat;
            font-size: 1rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-decoration: none;
            
            img{
                width: 0.6vw;
                margin-right: 10px;
            }
        }
        .ContentProjectsRight{
            text-decoration: none;

            color: #707070;
            font-family: Montserrat;
            font-size: 1.2rem;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    .ContentProjectsViewImgageOne , .ContentProjectsViewImgageTwo{
        width: 70vw;
        background-color: #CBCBCB;
        min-height: 25vw;
        margin : 0;
        margin-bottom: 2vw;
        display: flex;
        flex-direction: row;
        padding : 0;
    }

    .ContentProjectsViewImgageOne{
          text-decoration: none;
        .ContentProjectsViewImgageLeft{
            min-width: 45vw;
            max-width: 45vw;
            min-height:100%;
            max-height:100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-size: 100%;
            img{
                width: 100%;
            }
        }
        .ContentProjectsViewImgageRight{          
            min-width: 25vw;
            max-width: 25vw;
            min-height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text{
                min-height: 5%;
                font-family: Montserrat;
                margin-bottom: 8px;
            }
            .textOne{
                font-size: 1.2rem;
                color: #000;
                font-family: Montserrat;
            }
            .textTwo{
                font-size: 0.9rem;
                color: #939393;
                font-family: Montserrat
            }
            .textThree{
                min-width: 1.5vw;
                min-height: 1.5vw;
                max-height: 1.5vw;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                border-radius: 5%;
                background-color: #000;
                border-radius: 1.5vw;
                .Flecha{
                    min-width: 1.5vw;
                    background-image: url('../../img/flechanegra/Trazado.svg');
                    background-size: 40%;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
        }
    }

    .ContentProjectsViewImgageTwo{
        text-decoration: none;
        .ContentProjectsViewImgageLeft{
            min-width: 25vw;
            max-width: 25vw;
            min-height:100%;
            max-height:100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-decoration: none;
            

            text{
                min-height: 5%;
                font-family: Montserrat;
                margin-bottom: 8px;
            }
            .textOne{
                font-size: 1.2rem;
                color: #000;
                font-family: Montserrat;
            }
            .textTwo{
                font-size: 0.9rem;
                color: #939393;
                font-family: Montserrat
            }
            .textThree{
                min-width: 1.5vw;
                min-height: 1.5vw;
                max-height: 1.5vw;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                border-radius: 5%;
                background-color: #000;
                border-radius: 1.5vw;
                .Flecha{
                    min-width: 1.5vw;
                    background-image: url('../../img/flechanegra/Trazado.svg');
                    background-size: 40%;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }

        }
        .ContentProjectsViewImgageRight{
            min-width: 45vw;
            max-width: 45vw;
            min-height:100%;
            max-height:100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            img{
                width: 45vw;
            }
        }

    }



    .ContentProjectsViewImgageOne:hover{
        .ContentProjectsViewImgageLeft{
            background: rgba(0,0,0, .3);
            background-blend-mode: darken;
            background-repeat: no-repeat;
            background-size: 100%;
            img{
                filter: brightness(0.5);
            }
        }
        .ContentProjectsViewImgageRight{
            background-color:#000000;
            .textOne{
                color: #FFF;
            }
            .textTwo{
                font-size: 0.9rem;
            }
            .textThree{
                min-width: 1.5vw;
                min-height: 1.5vw;
                max-height: 1.5vw;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                border-radius: 5%;
                background-color: #FFF;
                border-radius: 1.5vw;
                .Flecha{
                    min-width: 1.5vw;
                    background-image: url('../../img/flecha/Trazado 239.svg');
                    background-size: 40%;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
        }
    }

    .ContentProjectsViewImgageTwo:hover{
        .ContentProjectsViewImgageLeft{
            background-color:#000000;
            .textOne{
                color: #FFF;
            }
            .textTwo{
                font-size: 0.9rem;
            }
            .textThree{
                min-width: 1.5vw;
                min-height: 1.5vw;
                max-height: 1.5vw;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                border-radius: 5%;
                background-color: #FFF;
                border-radius: 1.5vw;
                .Flecha{
                    min-width: 1.5vw;
                    background-image: url('../../img/flecha/Trazado 239.svg');
                    background-size: 40%;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
        }
        .ContentProjectsViewImgageRight{
            background: rgba(0,0,0, .3);
            background-blend-mode: darken;
            background-repeat: no-repeat;
            background-size: 100%;
            img{
                filter: brightness(0.5);
            }
        }
    }


}








@media (max-width:700px){
    .ContentProjects{
        min-width: 85vw;
        max-width: 85vw;
    
        .ContentProjectsTitle{
            min-width: 85vw;
            max-width: 85vw;
            min-height: 15vw;
            margin-bottom: 0vw;
            div{
            }
            .ContentProjectsLeft{
                min-width: 85vw;
                max-width: 85vw;
                img{
                    min-width: 3vw;
                }
            }
            .ContentProjectsRight{
                    display: none;
            }
        }
    
        .ContentProjectsViewImgageOne , .ContentProjectsViewImgageTwo{

        }
    
        .ContentProjectsViewImgageOne{
            min-width: 85vw;
            max-width: 85vw;
            min-height: 85vw;
            display: flex;
            flex-direction: column;
            margin-bottom: 5vw;
            .ContentProjectsViewImgageLeft{
                min-width: 85vw;
                max-width: 85vw;
                background-size: 100%;
                background-repeat: no-repeat;
            }
            .ContentProjectsViewImgageRight{
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 85vw;
                min-height: 25vw;
                text{
                }
                .textOne{
           
                }
                .textTwo{
           
                }
                .textThree{
                    border-radius: 2vw;
                    min-width: 4vw;
                    min-height: 4vw;
                    .Flecha{
                        min-width: 4vw;
                        height: 4vw;           

                    }
                }
            }
        }
    
        .ContentProjectsViewImgageTwo{
            min-width: 85vw;
            max-width: 85vw;
            min-height: 85vw;
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 5vw;
            padding: 0;
            .ContentProjectsViewImgageLeft{
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 85vw;
                min-height: 25vw;
                background-color: #CBCBCB;
                margin: 0;
                padding: 0;
                text{
                }
                .textOne{
                   
                }
                .textTwo{
         
                }
                .textThree{      
                    min-width: 4vw;
                    min-height: 4vw;
                    border-radius: 2vw;
                    .Flecha{
                        min-width: 4vw;
                        height: 4vw;     
                    }
                }
    
            }
            .ContentProjectsViewImgageRight{
                min-width: 85vw;
                max-width: 85vw;
                min-height: 60vw;
                background-color: #CBCBCB;
                display: flex;
                justify-content: flex-start;
                img{
                    width: 100%;
                }
            }
    
        }
    
    
    
        .ContentProjectsViewImgageOne:hover{
            background-color: #CBCBCB;
            .ContentProjectsViewImgageLeft{
                background: none;                
                img{
                    filter: brightness(1);
                }
            }
            .ContentProjectsViewImgageRight{
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 85vw;
                min-height: 25vw;
                background-color: #CBCBCB;
                text{
                }
                .textOne{
           
                }
                .textTwo{
           
                }
                .textThree{
                    border-radius: 2vw;
                    min-width: 4vw;
                    min-height: 4vw;
                    .Flecha{
                        min-width: 4vw;
                        height: 4vw;           

                    }
                }
            }
        }
    
        .ContentProjectsViewImgageTwo:hover{
            background-color: none;
            background:  #CBCBCB; 
            .ContentProjectsViewImgageLeft{
                background-color: #CBCBCB;
                .textOne{
                    color: #000;
                }
                .textTwo{
                }
                .textThree{   
                    border-radius: 2vw;
                    min-width: 4vw;
                    min-height: 4vw;
                    .Flecha{
                        min-width: 4vw;
                        height: 4vw;  
                    }                                          
                }
            }
            .ContentProjectsViewImgageRight{
                background: none; 
                img{
                    filter: brightness(1);
                }
            }
        }
    
    
    }
    
    
}