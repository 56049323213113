


@media (max-width:700px) and (orientation:portrait){
    .containerContentPageSeven{
        min-width: 100vw;
        min-height: 100vh;
        max-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }
    .containerInformation{
        width: 90vw;
        height: 52vh;
        .titleInformation{
            width: 100%;
            min-height: 30%;
            max-height: 30%;
            display: flex;
            justify-content: center;
            img{
                width: 60%;
                z-index: 100;
            }
        }
        .informationBotton{
            width: 100%;
            min-height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
                .textTwo{
                    color: #FFF;
                    font-size: 1.8rem;
                    font-family: Montserrat;
                    margin-bottom: 20%;
                text{
                    font-family: Montserrat;
                    font-size: 1rem;
                }
            }
            .iconsBottoms{
                justify-content: space-around;
                padding-left: 2.5vw;
                a{
                max-width: 25vw;
                min-width: 25vw;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    display: flex;
                    min-width: 10vw;
                    max-width: 10vw;
                    z-index: 500;
                }
                }
            }
            .textThree{
                max-width: 90vw;
                text-align: center;     
                .icon{
                    font-size: 0.9rem;
                }           
            }
        }
        
    }
    
    .bottonend{
        height: 100%;
        min-width: 90vw;
        .designBy{
            min-width: 40vw;
            margin-left: 50vw;
            .st1{
                fill: #FFF;
            }
            .st2{
                fill: #FFF;
            }
        }
    }
}






















@media (max-width:390px){
    .containerContentPageSeven{
        min-width: 100vw;
        min-height: 100vh;
        max-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }
    .containerInformation{
        width: 90vw;
        height: 52vh;
        .titleInformation{
            width: 100%;
            min-height: 30%;
            max-height: 30%;
            display: flex;
            justify-content: center;
            img{
                width: 60%;
            }
        }
        .informationBotton{
            width: 100%;
            min-height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
                .textTwo{
                    color: #FFF;
                    font-size: 1.8rem;
                    font-family: Montserrat;
                    margin-bottom: 15%;
                text{
                    font-family: Montserrat;
                    font-size: 1rem;
                }
            }
            .iconsBottoms{
                justify-content: space-around;
                padding-left: 2.5vw;
                a{
                max-width: 25vw;
                min-width: 25vw;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    display: flex;
                    min-width: "20vw";
                    max-width: "20vw";
                }
                }
            }
            .textThree{
                max-width: 95vw;
                text-align: center;     
                .icon{
                    font-size: 0.8rem;
                }           
            }
        }
        
    }
    
    .bottonend{
        height: 100%;
        min-width: 90vw;
        .designBy{
            min-width: 40vw;
            margin-left: 50vw;
            .st1{
                fill: #FFF;
            }
            .st2{
                fill: #FFF;
            }
        }
    }
}


@media (max-width: 1000px) and (orientation: landscape) {


    .containerContentPageSeven{
        min-width: 100vw;
        min-height: 120vh;
        max-height: 120vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }
    .containerInformation{
        width: 90vw;
        height: 52vh;
        .titleInformation{
            width: 100%;
            min-height: 30%;
            max-height: 30%;
            display: flex;
            justify-content: center;
            img{
                width: 20%;
            }
        }
        .informationBotton{
            width: 100%;
            min-height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
                .textTwo{
                    color: #FFF;
                    font-size: 1.8rem;
                    font-family: Montserrat;
                    margin-bottom: 20%;
                text{
                    font-family: Montserrat;
                    font-size: 1rem;
                }
            }
            .iconsBottoms{
                justify-content: space-around;
                min-height: 10vh;
                padding-left: 2.5vw;
                a{
                max-width: 10vw;
                min-width: 10vw;
                display: flex;
                justify-content: flex-start;
                }
            }
            .textThree{
               
                font-size: 0.9rem;
                font-family: Montserrat;
                margin-bottom: 2%;
                margin-top: 2%;
                color: #FFF;
                text{
                    color: #FFF;
                    font-family: Montserrat;
                    font-size: 1rem;
                    text-align: center;
                }
            }
        }
        
    }
    
    .bottonend{
        height: 100%;
        min-width: 90vw;
        .designBy{
            z-index: 80;
            display: flex;
            justify-content: center;
            min-height: 10vh;
            min-width: 20vw;
            margin-left: 70vw;
            .st1{
                fill: #FFF;
            }
            .st2{
                fill: #FFF;
            }
        }
    }


}