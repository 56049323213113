.containerContentPageSeven{
    min-width: 100vw;
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-color: transparent;

}
.containerInformation{
    z-index: 50;
    width: 90vw;
    height: 52vh;
    .titleInformation{
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        img{
            width: 20%;
        }
    }
    .informationBotton{
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
            .textTwo{
            color: #FFF;
            font-size: 1.8rem;
            font-family: Montserrat;
            text{
                font-family: Montserrat;
                font-size: 1rem;
            }
        }
        .iconsBottoms{
            justify-content: center;
            padding-left: 2.5vw;
            a{
            max-width: 5vw;
            min-width: 5vw;
            display: flex;
            justify-content: flex-start;
                img{
                    width: 30%;
                    min-width: "50px";
                    max-width: "50px";
                }
                .beImage{
                    width: 35%;
                }
            }
        }
        .textThree{            
            font-size: 0.9rem;
            font-family: Montserrat;
            margin-bottom: 2%;
            margin-top: 2%;
            color: #FFF;
            text{
                color: #FFF;
                font-family: Montserrat;
                font-size: 1rem;
            }
        }
    }
    
}

.bottonend{
    height: 100%;
    min-width: 90vw;
    min-height: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .designBy{
        min-width: 10vw;
        margin-left: 80vw;
        .st1{
            fill: #FFF;
        }
        .st2{
            fill: #FFF;
        }
    }
}















