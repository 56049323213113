@media (max-width: 820px ) and  (orientation:portrait) {


    .containerContentPageFour{
        z-index: 50;
        width: 100vw;
        min-height: 50vh;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .pageContentFour{
        z-index: 50;
        width: 90vw;
        height: 90vh;
        border-top: 1px solid rgba(255,255,255, .3);
        .pageContentFourHeader{
            width: 90vw;
            min-height: 10vh;
            display: flex;
            flex-direction: row;
            .pageContentFourHeaderLeft{
                width: 50%;
                min-height: 10vh;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                color: #FFF;
                font-size: 1rem;
                font-family: Montserrat;
                img{
                   // margin-right: 2%;                
                }
                .textwork{
                    margin-right: 2%;    
                }
            }
            .pageContentFourHeaderRight{
                width: 50%;
                min-height: 10vh;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                color: #FF4700;
                font-size: 1.5rem;
                font-family:  Montserrat;
            }
        }
        .pageContentFourCentral{
            width: 90vw;
            min-height: 40vh;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            .pageContentFourCentralContentOne{            
                width: 25vw;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-decoration: none;
                padding-top:0;
                .pageContentFourCentralContentOneImage{
                    width: 25vw;
                    min-width: 25vw;
                    min-height: 25vw;
                    margin-top: 0;
                    overflow-x: hidden;
                    overflow-y: hidden;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    transition: all .2s ease-in-out;
                    background-image: url('../../img/home/ELEMATE.jpg');
                    background-size: 110%;
                    background-repeat: no-repeat;
                    background-position: center;
    
                }
                
                .pageContentFourCentralContentOneFooter{
                    margin-top: 2vh;
                    .textOne{
                        min-height: 4vh;
                        border-bottom: 1px solid rgba(255,255,255, .3);
                        text-decoration: none;
                        min-width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        .Links{
                            display: flex;
                            align-items: center;
                            width: 100%;
                            text{
                                font-size: 1.5rem;
                                width: 95%;
                                min-height: 150%;
                                font-weight: 500;
                            }
                        }
                    }
                    .textTwo{
                        width: 100%;
                        min-height: 4vh;
                        display: flex;
                        align-items: center;
                        color: #939393;
                        font-family:  Montserrat;
                        font-size: 1rem;
                        display: flex;
                    }              
                }
            }
        
            .pageContentFourCentralContentTwo{
                width: 25vw;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 5vw;
                text-decoration: none;
                .pageContentFourCentralContentTwoImage{
                    min-width: 25vw;
                    min-height: 25vw;
                    overflow-x: hidden;
                    overflow-y: hidden;
                    transition: all .2s ease-in-out;
                    background-image: url('../../img/home/AMUZE.jpg');
                    background-size: 130%;
                    background-repeat: no-repeat;
                    background-position: center;
                }    
                .pageContentFourCentralContentTwoFooter{
                    margin-top: 2vh;
                    .textOne{
                        min-height: 4vh;
                        border-bottom: 1px solid rgba(255,255,255, .3);
                        text-decoration: none;
                        min-width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        .Links{
                            display: flex;
                            align-items: center;
                            width: 100%;
                            text{
                                font-size: 1.5rem;
                                width: 95%;
                                min-height: 150%;
                                font-weight: 500;
                            }
                        }
                    }
                    .textTwo{
                        width: 100%;
                        min-height: 4vh;
                        display: flex;
                        align-items: center;
                        color: #939393;
                        font-family:  Montserrat;
                        font-size: 1rem;
                        display: flex;
                    }
                }
            }
            .pageContentFourCentralContentThree{
                margin-left: 5vw;
                width: 25vw;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-decoration: none;
               
                .pageContentFourCentralContentThreeImage{
                    min-width: 25vw;
                    min-height: 25vw;
                    overflow-x: hidden;
                    overflow-y: hidden;
                    display: flex;justify-content: center;
                    align-items: center;
                    transition: all .2s ease-in-out;
                    background-image: url('../../img/home/BIG\ CITY.jpg');
                    background-size: 130%;
                    background-repeat: no-repeat;
                    background-position: center;                
                }                
                .pageContentFourCentralContentThreeFooter{
                    margin-top: 2vh;
                    .textOne{
                        min-height: 4vh;
                        border-bottom: 1px solid rgba(255,255,255, .3);
                        text-decoration: none;
                        min-width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        .Links{
                            display: flex;
                            align-items: center;
                            width: 100%;
                            text{
                                font-size: 1.5rem;
                                width: 95%;
                                min-height: 150%;
                                font-weight: 500;
                            }
                        }
                    }
                    .textTwo{
                        width: 100%;
                        min-height: 4vh;
                        display: flex;
                        align-items: center;
                        color: #939393;
                        font-family:  Montserrat;
                        font-size: 1rem;
                        display: flex;
                    }
                }
            }
    
            .pageContentFourCentralContentOneImage:hover, .pageContentFourCentralContentTwoImage:hover, .pageContentFourCentralContentThreeImage:hover{
                opacity: 0.5;
            }
            #algo, #algo, #algo{
                display: flex;
                justify-content: center;
                overflow-x: hidden;
            }
            
            #algo:hover, #algo:hover, #algo:hover{
                background-repeat: no-repeat;
                background-image:   linear-gradient(to right, #C9C9C9 100%, #C9C9C9 100%),
                                    linear-gradient(to bottom, #C9C9C9 100%, #C9C9C9 100%),
                                    linear-gradient(to right, #C9C9C9 100%, #C9C9C9 100%),
                                    linear-gradient(to bottom, #C9C9C9 100%, #C9C9C9 100%);
                background-size:    100% 1px,
                                    1px 100%,
                                    100% 1px,
                                    1px 100%;
                background-position:    0 0,
                                        100% 0,
                                        100% 100%,
                                        0 100%;
                animation: bgProjectos 1s;
            }
    
            @keyframes bgProjectos {
                0% {
                    background-size:    0 1px,
                                        1px 0,
                                        0 1px,
                                        1px 0;
                }
                25% {
                    background-size:    100% 1px,
                                        1px 0,
                                        0 1px,
                                        1px 0;
                }
                50% {
                    background-size:    100% 1px,
                                        1px 100%,
                                        0 1px,
                                        1px 0;
                }
                75% {
                    background-size:    100% 1px,
                                        1px 100%,
                                        100% 1px,
                                        1px 0;
                }
                100% {
                    background-size:    100% 1px,
                                        1px 100%,
                                        100% 1px,
                                        1px 100%;
                }
            }
    
        }
    
    
    
    
    
        .pageContentFourFooter{
            width: 90vw;
            min-height: 0vh;
            display: flex;
            flex-direction: row;
            .pageContentFourFooterLeft{
                width: 50%;
                min-height: 5vh;
            }
            .pageContentFourFooterRight{
                width: 50%;
                min-height: 0vh;
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                border-bottom: 0.5px solid rgba(255,255,255, .3);
                color: #FFF;
                .Links{
                    width: 100%;
                    text{
                        font-size: 1.2rem;
                        font-weight: 400;
                    }
    
    
                    background-image:   linear-gradient(to right, #C9C9C9 100%, #C9C9C9 100%);
                    background-size:    90% 100%;//,
                    background-position:   0% 100%;
    
                    background-repeat: no-repeat;
                    background-image:   linear-gradient(to right, #C9C9C9 100%, #C9C9C9 100%);
                    background-size:    100% 1%;//,
                    background-position:   0% 99%;  
                }
                .Links:hover{                        
                    animation: TitleHoverSubPageTwo 1.25s cubic-bezier(0.19, 1, 0.22, 1) 1;      
                }
                text{
                    font-size: 1.5rem;
                    font-family: Montserrat;
                    margin-right: 5%;                       
                }
            }
    
        }
    }
    
    
}
















