
.App {
  margin: 0;
  padding: 0;
text-align: left;
min-width: 100vw;
max-width: 100vw;
min-height: 100vh;
//max-height: 100vh;
display: flex;
flex-direction: column;
}
/*
.App::-webkit-scrollbar{
 // width: .3vw;     //Tamaño de scrool en vertical
  height: .3vw;    //Tamaño se scroll en horizontal
}

.App::-webkit-scrollbar-thumb{
  background: #CCC;
  border-radius: 4px;
}

.App::-webkit-scrollbar-thumb:hover{
  background: #B3B3B3;
  box-shadow: 0 0 2px 1px rgba(0,0,0,0.2);  
}

*/
/* Cambiamos el fondo y agregamos una sombra cuando este en hover */
/*
.App::-webkit-scrollbar-thumb:active{
  background-color: #999;
}
*/



@media (max-width: 700px)  {
  .App{
    margin: 0;
    padding: 0;
    min-width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
    //overflow-y: scroll;
    display: flex;
    justify-content: flex-start;
    margin: 0px;
    padding: 0px;
  }
}
        