.containerDetailSix{
    min-width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    .ContentProjects{
        border-top: 1px solid rgba(0,0,0, .3);
        width: 90vw;
        margin-top: 15vh;
        min-height: 100vh;
        margin-bottom:10vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 5vw;
        
        .ContentProjectsViewImgageOneDetailSix{
            max-width: 70vw;
            min-width: 70vw;
            min-height: 60vh;
            display: flex;
            margin-bottom: 2vw;
            .ContentProjectsViewImgageLeft{
                min-width: 100%;
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
     


        
        .ContentProjectsViewText{
            max-width: 78%;
            font-family: Montserrat;
            font-size: 1 rem;
            margin-bottom: 2vw;
            display: flex;
            flex-direction: column;
            text{
                margin-left: 1vw;
                margin-right: 1vw;
                font-size: 1rem;
                font-family: Montserrat;
                color: #707070;
                display: flex;
                flex-direction: column;
                text-align: justify;
                line-height: 2rem;
                label{
                    margin-top: 20px;
                    margin-bottom: 10px;
                    font-weight: 500;
                    font-size: 1.1rem;
                }
            }
            .title{
                font-size: 1.4rem;
                font-family: Montserrat;
            }

            
        }
        .ContentProjectsViewImgageTwoDetailSix{
            max-width: 70vw;
            min-width: 70vw;
            min-height: 60vh;
            display: flex;
            margin-bottom: 2vw;
            .ContentProjectsViewImgageRight{            
                min-width: 100%;
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }
}










.VideoRW{
    min-width: 90vw;
    min-height: 50vw;
    margin-bottom: 3vw;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    .react-player{
        min-height: 70vh;
    }
}


@media (max-width:700px){
    .containerDetailSix{     
        .ContentProjectsTitle{
            display: none;
        }
        .ContentProjects{
            margin-left: 0;
            margin-right: 0;    
            max-width: 99.7vw;
            min-width: 99.7vw;    
            display: flex;
            .ContentProjectsViewImgageOneDetailSix{
                max-width: 99.7vw;
                min-width: 99.7vw;
                min-height: 30vh;
                display: flex;
                margin-bottom: 2vw;
                .ContentProjectsViewImgageLeft{                    
                    min-width: 100%;
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
         
            .ContentProjectsViewText{
                max-width: 90vw;
                font-family: Montserrat;
                margin-bottom: 2vw;
                display: flex;
                flex-direction: column;
                text{
                    font-size: 0.9rem;
                    font-family: Montserrat;
                    display: flex;
                    flex-direction: column;
                    text-align: justify;
                    label{
                        margin-top: 20px;
                        margin-bottom: 10px;
                    }
                }
                .title{
                    font-size: 1.2rem;
                    font-family: Montserrat;
                }
            }
            .ContentProjectsViewImgageTwoDetailSix{
                max-width: 99.7vw;
                min-width: 99.7vw;
                min-height: 30vh;
                display: flex;
                margin-bottom: 2vw;
                .ContentProjectsViewImgageRight{            
                    min-width: 100%;
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
        }
    }

    .Video{
        min-width: 100vw;
        min-height: 50vh;
        margin-bottom: 3vw;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 0;
        .react-player{
            min-height: 50vh;
        }
    }
    
}