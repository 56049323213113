.containerContentPageSix{
    min-width: 100vw;
    min-height: 70vh;
    max-height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.TitleContainerPageSix{
    border-top: 1px solid rgba(255,255,255, .3);
    //border-top: 1px solid white;
    margin-top:20vh;
    width: 90vw;
    min-height:10vh;
    display: flex;
    
    .titleLeftPageSix{
        width: 50%;
        min-height:100%;
        display: flex;
        flex-direction: row;
       justify-content: flex-start;
       align-items: center;
            color: white;
            font-size: 1rem;
            font-family: Montserrat;
        img{
            margin-right: 3%;
        }
    }
    .titleRightPageSix{
        width: 50%;
        min-height:100%;
        display: flex;
        justify-content: flex-end;
        color: #FF4700;
        align-items: center;
        font-family: Montserrat;
        font-size: 1.8rem;
        font-weight: 400;
    }
}
.contentcontainerPageSix{
   margin-top: 10vh;
    width:85vw;
    min-height:40vh;
    display: flex;
    justify-content: center;
    align-items: center;
}







 @media (max-width:700px ) {
    .titleRightPageSix{
        visibility: hidden;
    }
 }