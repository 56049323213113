

.absoluteServices{
    background-color: #000000;    
}

    /*     PAGES        */

    .containerHomePage{
        min-width: 100vw;
        max-width: 100vw;
        padding: 0;
        margin: 0;
        min-height: 100vh;
  
    }

    .containerProjects{
        min-width: 100vw;
        max-width: 100vw;
        min-height: 200vh;
    }

    .absoluteServices{
        min-width: 100vw;
        max-width: 100vw;
    }

    .Links{
        text-decoration: none;
        cursor: pointer;
        width: 80%;
        display: flex;
        justify-content: flex-start;
        list-style: none;
        color: #FFF;
        font-size: 3rem;
        font-family: Montserrat;
        min-height: 25%;
        font-weight: 600;
    }
    .Links:hover{
        color: #FF4700;
    }



















@media (max-width: 700px)  {

 
        
    /*     HEADER CLOSE     */
    .HeaderNavContainer{
        position: absolute;
        z-index: 20;
        min-width: 100vw;
        max-width: 100vw;
        min-height: 8vh;
        .navigationMenu{
            min-width: 100vw;
            max-width: 100vw;
            .MenuBottoms{
                display: flex;
                flex-direction: row;
                .containerTBE{
                    cursor: pointer;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
              //      background-color: #FF4700;
                    min-height: 8vh;
                    min-width: 15vw;
                    img{
                        min-width: 10vw;
                        max-width: 10vw;
                    }
                }
                .containerLinks{
                    flex-grow: 3;
                    display: flex;
                    justify-content: flex-end;               
                    align-items: center;
                    background-color: #FF4700;
                    padding-right: 5vw;
                    img{
                        margin-left: 5vw;
                        width: 5vw;
                    }
                }
                .containerBotton{
                    background-color: #FF4700;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    min-width: 15vw;
                    color: #FFF;
                }
            }
        }

    }



    /*      HEADER CLOSED       */

    .HeaderNavContainerOpen{
        position: absolute;
        z-index: 50;
        min-width: 100vw;
        max-width: 100vw;
        min-height: 8vh;
        display: flex;
        flex-direction: column;
        
        .navigationMenu{
            min-width: 99.8vw;
            max-width: 100vw;
            .MenuBottoms{
                display: flex;
                flex-direction: row;
                min-height: 8vh;             
                .containerTBE{
                    background-color: #FF4700;
                    cursor: pointer;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    min-width: 15vw;
                    img{
                        min-width: 10vw;
                        max-width: 10vw;
                    }
                }
                .containerLinks{
                    flex-grow: 3;
                    background-color: rgba( 0,0,0,  0.8);

                    display: flex;
                    justify-content: flex-end;                
                    align-items: center;
                    padding-right: 5vw;
                    img{
                        margin-left: 5vw;
                        min-width: 5vw;
                    }
                }
                .containerBotton{
                    background-color: rgba( 0,0,0,  0.8);
                
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 15vw;
                    color: #FFF;
                }
            }
        }
        .MenuTitleHeaders{
            min-height: 90vh;
            display: flex;
            flex-direction: row;
            div{
                width: 30vw;
            }
            .elementThree {
            background-color: rgba( 0,0,0,  0.8);
            min-width: 85vw;
                flex-grow: 2;
                display: flex;
                flex-direction: column;
                ul{
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;
                    width: 100%;
                    min-height: 50%;
                    li{
                        cursor: pointer;
                        width: 80%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        border-bottom: 1px solid #FFF;
                        list-style: none;
                        color: #FFF;
                        font-size: 3rem;
                        font-family: Montserrat;
                        min-height:20%;
                        font-weight: 600;
                    }
                    li:hover{
                        color: black;
                    }
                }            
                div{
                    min-width: 90%;
                    margin-left: 10%;
                    min-height: 10%;
                    display: flex;
                    justify-content: flex-start;
                    img{
                        color: #FF4700;
                        width: 10%;
                        margin-right: 10%;
                    }
                }
            }
        }
    }



    .absoluteServices{
        //background-color: #000000;
      //  $list3: #000000 0%,#000000 15%, #000000 35%,#000000 85%,  #FF4700 100%; 
      //  background: -webkit-linear-gradient($list3);     
    }


    /*     PAGES        */

    .containerHomePage{
        margin: 0;
        padding: 0;
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        $list3: transparent 0%, transparent 10%, transparent 25%, transparent 39%, #000000 90%, #000 100%; 
        background: -webkit-linear-gradient($list3);
        position: absolute;
        
    }

    .containerProjects{
        min-width: 100vw;
        max-width: 100vw;
        min-height: 200vh;
    }



    .Links{
        text-decoration: none;
        cursor: pointer;
        width: 80%;
        display: flex;
        justify-content: flex-start;
        list-style: none;
        color: #FFF;
        font-size: 3rem;
        font-family: Montserrat;
        min-height: 25%;
        font-weight: 600;
    }
    .Links:hover{
        color: #FF4700;
    }
}
















































