

@media (max-width: 700px)  {


    .containerTransicion{
   
        margin: 0;
        padding: 0;
        min-width: 80vw;
        max-width: 80vw;
        min-height: 40vh;
        max-height: 40vh;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: #000;
        .Imagen1{
            margin-top: 5vh;
            border: none;
            position: absolute;          
            min-width: 60vw;
            max-width: 60vw;
        }
        @keyframes AnimacionUno {
            0%{
                opacity: 1;
            }
            50%{
                opacity: 0;
            }
            100%{
                opacity: 1;
            }        
        }
       
        .Imagen2{
            margin-top: 5vh;
            border: none;
            position: absolute;
            z-index: 20;
            min-width: 60vw;
            max-width: 60vw;
            max-height: 10vw;
            animation: AnimacionUno 5s infinite;
        }
    }   
}




@media (max-width: 400px) {


    .containerTransicion{
        min-width: 70vw;
        max-width: 70vw;
        min-height: 40vh;
        max-height: 40vh;
        display: flex;
        justify-content: center;
        align-items: center;
        .Imagen1{
            border: 1px solid;
            position: absolute;
            min-width: 60vw;
            max-height: 80vw;
            aspect-ratio: 1;
        }
        @keyframes AnimacionUno {
            0%{
                opacity: 1;
            }
            50%{
                opacity: 0;
            }
            100%{
                opacity: 1;
            }        
        }
       
        .Imagen2{
            position: absolute;
            z-index: 20;
            min-width: 60vw;
            max-height: 80vw;
            aspect-ratio: 1;
            animation: AnimacionUno 5s infinite;
        }
    }   
}