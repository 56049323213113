@mixin white-gradient {
	//background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 20%);
}

@media (max-width: 700px) {

	$animationSpeedFive: 20s;
	// Styling
	.sliderFive {

		&::before,
		&::after {
			@include white-gradient;
	
		}
		@keyframes scrollFive {
			0% { transform: translateX(0); }
			100% { transform: translateX(calc(-80vw * 9))}
		}
		&::after {
		
		}
	
		&::before {
			
		}
		
		.slide-trackFive {
			width: calc(80vw * 18);
		}
		.slide-trackFive:hover {
			//animation-play-state: paused;
		}


		.slideFive {
		
			margin-top: 5vh;
			min-width: 80vw;			
			display: flex;
			justify-content: cent er;
			align-items: flex-start;
			margin: 0;
			padding: 0;
			
			.texxto{			
				min-width: 10vw;
				z-index: 20;
				margin-left: 0vw;
				margin-top: 40vh;
				position: absolute;		
				
				.textNameTeam{	
					display: flex;
					flex-direction: column;
					font-family: Montserrat;
				
					.Name{
						color: #FF4700;;
						text{
							color: white;
						}
						margin-bottom: 15px;
					}
					.position{
						
						padding-top: 15px;
						border-top: 1px solid rgba(255,255,255, .3);
						color: #939393;
					}
				}
			}		
		}
	}	





	.containerContentPageFiveContentPrincipalFooter{
		margin-top: 3vh;		
	}
	.imagenCreate{
		
		max-width: 80vw;
		margin-left: 10vw;
	}
}
























@media (max-width: 400px) {

	// Styling
	.sliderFive {
		
		&::before,
		&::after {
			@include white-gradient;
	
		}
		@keyframes scrollFive {
			0% { transform: translateX(0); }
			100% { transform: translateX(calc(-80vw * 8))}
		}
		&::after {
		
		}
	
		&::before {
			
		}
		
		.slide-trackFive {
			width: calc(80vw * 16);
		}
		.slide-trackFive:hover {
		//	animation-play-state: paused;
		}
		.slideFive {
			margin-top: 0vh;
			min-width: 80vw;			
			display: flex;
			padding: 0;
			margin: 0;
			justify-content: center;
			align-items: flex-start;
			display: flex;

			div{
			
			}
			.section{
				z-index: 200;
			}

			.texxto{
				margin-top: 3vh;
				display: flex;
				.textNameTeam{
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}
	}	
} 

