    .containerContentPageOne{
        min-width: 100vw;
        min-height: 100vh;  
        max-height: 100vh;  
        font-family: "SummerFaith-Regular";
        overflow-y: scroll;
        transition: opacity(0.1s);        
        flex-direction: column;
        display: flex;
        flex-direction: column;
        background-color: transparent;
     
        

        &::-webkit-scrollbar {
            width: 0 !important
          }
          -ms-overflow-style: none;
          scrollbar-width: none;
    }
    
    .contentPageOne{
        width: 100vw;
        min-height: 100vh;
        max-height: 100vh;
      
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        scroll-snap-stop: always;

      
        z-index: 15;
        position: -webkit-sticky;  // required for Safari
        position: sticky;
        top: 0; // required as well.
        overflow: hidden;
    
    }
    /*
    .oneContentPageOne{
        align-self: stretch;
        min-width: 92vw;
        max-width: 92vw;
        min-height: 30vh;
        max-height: 30vh;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-left: 4vw;

        border-bottom: 1px solid rgba(255,255, 255 , 0.4);
        
        img{
            width: 25%;
        }
        .Brandinf{
            min-width: 40vw;
        }
  
    }
    .contentPageOne .twoContentPageOne{
        margin-top: 8%;
        transform: rotate(-7deg);
        min-width: 40%;
        min-height: 20vh;
        max-height: 20vh;
        display: flex;
        align-items: flex-end;
        font-size: 10rem;

      
    }
    .twoContentPageOne{
        font-weight: 500;
        margin-left: 5vw;
        font-weight: 500;
        margin-bottom: 4vh;
        color: #FFF;
      //  color: white; text-shadow: black 0.1em 0.1em 0.2em;
    }
    .threeContentPageOne{
        border-top: 1px solid rgba(255,255,255, .3);
        min-width: 90vw;
        min-height: 20vh;
        max-height: 20vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .slideHorizontal{
            z-index: 1;
            width: 100%;
            display: flex;        
            flex-direction: column;
            min-height: 12vh;
            max-height: 12vh;
            overflow-x:hidden;
            overflow-y: auto;   
        
            .subContentOnePageOne{
                width: 30vw;
                margin-left: 60vw;
                min-height: 12vh;
                max-height: 12vh;
                margin-bottom: 5vh;
                font-family:  Montserrat;
                color: #FFFFFF;
                font-size: 2rem;
                font-weight: 700;
                display: flex;            
                align-items: center;
            }
            .subContentTwoPageOne{
                width: 20vw;
                min-height: 12vh;
                max-height: 12vh;
                margin-left: 70vw;
                font-family:  Montserrat;
                color: #FFFFFF;
                font-weight: 700;
                font-size: 2rem;
                display: flex;            
                align-items: center;
            }
        }
        
    }
    .Flecha{
        min-height: 8vh;
        display: flex;
        justify-content: center;
        align-items: center;  
        
        
        
        .Imagen{
            min-width: 5vh;
            min-height: 5vh;
            background-size: 100%;
            display: flex;
            border-radius: 5vh;
            justify-content: center;
            align-items: center;
            img{
                width: 30%;
                transform: rotate(90deg);
            }
        }  
        
    }
    */
    .slideHorizontal::-webkit-scrollbar {
        display: none;
    } 



    .TextoAparece{
        animation: aparecerTexto 3s;
    }
    @keyframes aparecerTexto {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
        
    }

    .Texto1{
        z-index: 10;
        min-width: 100vw;
        min-height: 100vh;
        scroll-snap-stop: always;
        font-size: 4rem;
        font-family: Montserrat;
        display: flex;
        justify-content: flex-end;
        text{
            min-height: 20vh;
            max-height: 20vh;
            min-width: 40vw;
            max-width: 40vw;
            text-align: left;
            margin-right: 0vw;
            color: #FFF;
        }
    }
    .Texto2{
        z-index: 10;
        min-width: 100vw;
        min-height: 100vh;
        scroll-snap-stop: always;
        font-size: 4.5rem;
        font-family: Montserrat;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        
        text{
            min-height: 20vh;
            max-height: 20vh;
            min-width: 30vw;
            max-width: 30vw;
            text-align: right;
            margin-right: 4vw;
            color: #FFF;
        }
    }


.react-player{
    min-width: 120vw;
    min-height: 120vh;
    opacity: 1;
}










.react-playerMobil{
    min-width: 120vw;
    min-height: 120vh;
    display: none;
}

@media (max-width: 700px) {

    .react-player{
        min-width: 120vw;
        min-height: 120vh;
        display: none;
    }
    .react-playerMobil{
        min-width: 120vw;
        min-height: 120vh;
        display: flex;
        background-image: url('./Introvertical.gif');
        background-size: 70vh;
        margin-top: -10vh;
        background-position: center;
        background-repeat: no-repeat;

    }
    
}






