

@media (max-width: 700px) {
    .containerContentPageFour{
        min-width: 99.7vw;
        max-width: 99.7vw;
        min-height: 430vw;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .pageContentFour{
        min-width: 85vw;
        max-width: 85vw;
        .pageContentFourHeader{
        min-width: 50vw;
        max-width: 50vw;
        margin-bottom: 5vh;
            .pageContentFourHeaderLeft{
                .textwork{
                    max-width: 3vw;
                    min-width: 3vw;
                    margin-right: 2vw;
                }
            }
            .pageContentFourHeaderRight{
             display: none;
            }
        }
        .pageContentFourCentral{
            min-width: 85vw;
            max-width: 85vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            .pageContentFourCentralContentOne{            
                min-width: 85vw;
                min-height: 85vw;
                .pageContentFourCentralContentOneImage{
                    min-width: 85vw;
                    min-height: 85vw;
                    margin-bottom: 5vw;
                }
                .pageContentFourCentralContentOneImage:hover{
                }


                .pageContentFourCentralContentOneImage:hover{
                    opacity: 1;
                }
                #algo, #algo, #algo{
                    overflow-x: hidden;
                }                
                #algo:hover, #algo:hover, #algo:hover{
                    background-image:   none;
                    animation: none;
                }
        
        

                .pageContentFourCentralContentOneImage::-webkit-scrollbar {
                }
                .pageContentFourCentralContentOneFooter{
                 
                    .Links{
                   
                        text{
                            font-size: 1.2rem;
                        }
                        
                    }
    
                    .textTwo{
                        margin-top: 3vw;
                        font-size: 0.9rem;
                    }
                    margin-bottom: 5vw;
                }
            }
            .pageContentFourCentralContentTwo{
                margin: 0;
                min-width: 85vw;
                min-height: 85vw;
                .pageContentFourCentralContentTwoImage{
                    min-width: 85vw;
                    max-width: 85vw;
                    min-height: 85vw;
                    max-height: 85vw;
                    margin-bottom: 5vw;
                }

                .pageContentFourCentralContentTwoImage:hover{
                    opacity: 1;
                }
                #algo, #algo, #algo{
                    overflow-x: hidden;
                }                
                #algo:hover, #algo:hover, #algo:hover{
                    background-image:   none;
                    animation: none;
                }
        
                .pageContentFourCentralContentTwoImage:hover{
                }
                .pageContentFourCentralContentTwoImage::-webkit-scrollbar {
                }
                .pageContentFourCentralContentTwoFooter{
                 
                    .Links{
           
                        text{
                            font-size: 1.2rem;
                        }
                        
                    }
    
                    .textTwo{
                        margin-top: 3vw;
                        font-size: 0.9rem;
                    }
                    margin-bottom: 5vw;
                    
                }
            }
            .pageContentFourCentralContentThree{
                margin: 0;
                min-width: 85vw;
                min-height: 85vw;
                .pageContentFourCentralContentThreeImage{
                    min-width: 85vw;
                    max-width: 85vw;
                    min-height: 85vw;
                    max-height: 85vw;
                    margin-bottom: 5vw;
                }
                .pageContentFourCentralContentThreeImage:hover{
                    opacity: 1;
                }
                #algo, #algo, #algo{
                    overflow-x: hidden;
                }                
                #algo:hover, #algo:hover, #algo:hover{
                    background-image:   none;
                    animation: none;
                }

                .pageContentFourCentralContentThreeImage:hover{
                }



                .pageContentFourCentralContentThreeImage::-webkit-scrollbar {
                }
                
                .pageContentFourCentralContentThreeFooter{
           
                    .Links{
                        cursor: pointer;
                   
                        text{
                            font-size: 1.2rem;
                        }
                        
                    }
    
                    .textTwo{
                        margin-top: 3vw;
                        font-size: 0.9rem;
                    }
                    margin-bottom: 5vw;
                    
                }
            }
    
        }
        .pageContentFourFooter{
            margin: 0;
            min-width: 85vw;
            max-width: 85vw;
            .pageContentFourFooterLeft{
              display: none;
            }
            .pageContentFourFooterRight{
                margin: 0;
                min-width: 55vw;
                .Links{
                    text{
                        min-width: 85%;
                        font-size: 1rem;
                    }
                }
                text{
                    
                }
            }
            .pageContentFourFooterRight:hover{
                animation: none;
            }
        }
    }
}











@media (max-width: 500px) and (orientation: portrait) {
    .containerContentPageFour{
        min-width: 100vw;
        max-width: 100vw;
        min-height: 430vw;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .pageContentFour{
        min-width: 85vw;
        max-width: 85vw;
        .pageContentFourHeader{
        min-width: 50vw;
        max-width: 50vw;
        margin-bottom: 5vh;
            .pageContentFourHeaderLeft{
                min-width: 80%;
                .textwork{
                    max-width: 3vw;
                    min-width: 3vw;
                    margin-right: 2vw;
                }
            }
            .pageContentFourHeaderRight{
             display: none;
            }
        }
        .pageContentFourCentral{
            min-width: 85vw;
            max-width: 85vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            .pageContentFourCentralContentOne{            
                min-width: 85vw;
                min-height: 85vw;
                .pageContentFourCentralContentOneImage{
                    min-width: 85vw;
                    min-height: 85vw;
                    margin-bottom: 5vw;
                }
                .pageContentFourCentralContentOneImage:hover{
                }


                .pageContentFourCentralContentOneImage:hover{
                    opacity: 1;
                }
                #algo, #algo, #algo{
                    overflow-x: hidden;
                }                
                #algo:hover, #algo:hover, #algo:hover{
                    background-image:   none;
                    animation: none;
                }
        
        

                .pageContentFourCentralContentOneImage::-webkit-scrollbar {
                }
                .pageContentFourCentralContentOneFooter{
                 
                    .Links{
                   
                        text{
                            font-size: 1.2rem;
                        }
                        
                    }
    
                    .textTwo{
                        margin-top: 3vw;
                        font-size: 0.9rem;
                    }
                    margin-bottom: 5vw;
                }
            }
            .pageContentFourCentralContentTwo{
                margin: 0;
                min-width: 85vw;
                min-height: 85vw;
                .pageContentFourCentralContentTwoImage{
                    min-width: 85vw;
                    max-width: 85vw;
                    min-height: 85vw;
                    max-height: 85vw;
                    margin-bottom: 5vw;
                }

                .pageContentFourCentralContentTwoImage:hover{
                    opacity: 1;
                }
                #algo, #algo, #algo{
                    overflow-x: hidden;
                }                
                #algo:hover, #algo:hover, #algo:hover{
                    background-image:   none;
                    animation: none;
                }
        
                .pageContentFourCentralContentTwoImage:hover{
                }
                .pageContentFourCentralContentTwoImage::-webkit-scrollbar {
                }
                .pageContentFourCentralContentTwoFooter{
                 
                    .Links{
           
                        text{
                            font-size: 1.2rem;
                        }
                        
                    }
    
                    .textTwo{
                        margin-top: 3vw;
                        font-size: 0.9rem;
                    }
                    margin-bottom: 5vw;
                    
                }
            }
            .pageContentFourCentralContentThree{
                margin: 0;
                min-width: 85vw;
                min-height: 85vw;
                .pageContentFourCentralContentThreeImage{
                    min-width: 85vw;
                    max-width: 85vw;
                    min-height: 85vw;
                    max-height: 85vw;
                    margin-bottom: 5vw;
                }
                .pageContentFourCentralContentThreeImage:hover{
                    opacity: 1;
                }
                #algo, #algo, #algo{
                    overflow-x: hidden;
                }                
                #algo:hover, #algo:hover, #algo:hover{
                    background-image:   none;
                    animation: none;
                }

                .pageContentFourCentralContentThreeImage:hover{
                }



                .pageContentFourCentralContentThreeImage::-webkit-scrollbar {
                }
                
                .pageContentFourCentralContentThreeFooter{
           
                    .Links{
                        cursor: pointer;
                   
                        text{
                            font-size: 1.2rem;
                        }
                        
                    }
    
                    .textTwo{
                        margin-top: 3vw;
                        font-size: 0.9rem;
                    }
                    margin-bottom: 5vw;
                    
                }
            }
    
        }
        .pageContentFourFooter{
            margin: 0;
            min-width: 85vw;
            max-width: 85vw;
            .pageContentFourFooterLeft{
              display: none;
            }
            .pageContentFourFooterRight{
                margin: 0;
                min-width: 55vw;
                .Links{
                    text{
                        min-width: 85%;
                        font-size: 1rem;
                    }
                }
                text{
                    
                }
            }
            .pageContentFourFooterRight:hover{
                animation: none;
            }
        }
    }
}