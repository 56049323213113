.containerContentPageSeven{
    min-width: 100vw;
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}
.containerInformation{
    border-top: 1px solid rgba(0,0,0, .5);
    border-bottom: 1px solid rgba(0,0,0, .5);
    width: 90vw;
    height: 52vh;
    .titleInformation{
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        text{
            font-size: 3.5rem;
            font-family: Montserrat;
            font-weight: 600;
            label{
                font-size: 1.2rem;
            }
            text{
                font-family: SummerFaith;
            }
        }
    }
    .informationBotton{
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    

        .textTwo{
            color: #000;
            font-size: 1.8rem;
            font-family: Montserrat;
            text{
                font-family: Montserrat;
                font-size: 1rem;
            }
        }
        .textThree{
            color: #000;
            font-size: 1rem;
            font-family: Montserrat;
            margin-bottom: 2%;
            margin-top: 2%;
            text{
                font-family: Montserrat;
                font-size: 1rem;
            }
        }
    }
    
}

.bottonend{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
    width: 40vw;
    .designByBlack{
        min-width: 10vw;
        margin-left: 80vw;
        .st1{
            fill: #000;
        }
    }
}




