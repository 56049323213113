

    

        
    @media (max-width: 700px) and (orientation: portrait) {
        @keyframes MenuContener {
            0% {
                min-height:  0vh;
                max-height:  0vh;
            }
            
            100% {
                min-height:  93vh;
                max-height:  93vh;
            }
        }
        
        .HeaderNavContainer, .HeaderNavContainerWhite, .HeaderNavContainerBlack{
            z-index: 50;
            min-width: 100vw;
            max-width: 100vw;
            min-height: 8vh;
            display: flex;
            flex-direction: column;
            background-color: none;
            .navigationMenu{
                min-width: 100vw;
                max-width: 100vw;
                background-color: none;
            
                .MenuBottoms{
                    display: flex;
                    flex-direction: row;
                    min-height: 8vh;             
                    
                    .containerTBE{
                        background-color: transparent;
                        cursor: pointer;
                        display: flex;
                        margin: 0;
                        align-items: center;
                        justify-content:  flex-end;
                        min-height: 8vh;
                        min-width: 30vw;
                        width: 20vw;
                        .LinkLogo{
                            max-width: 10vw;     
                        }
                        .LinkLogo >  svg{
                            width: 15vw;
                        }
                    }
                    .containerLinks{

                        background-color: #FF4700;
                        display: flex;
                        justify-content: flex-end;                
                        align-items: center;
                        padding-right: 5vw;
                        background-color: none;
                        .imagen{
                            display: none;
                            margin-right: 10vw;
                        }
                        .imagenMovil{
                            display: flex;
                            margin-right: 10vw;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 0;
                            img{
                                width: 75%;
                            }
                        }
                    }
                    .containerBotton{
                background-color: #FF4700;

                        display: flex;                    
                        justify-content: flex-start;
                        align-items: center;
                        min-width: 15vw;
                        
                        color: #FFF;
                    }
                }
            }
            .MenuTitleHeaders{
                min-height: 0vh;
                display: flex;
                flex-direction: row;
                div{
                    width: 30vw;
                }
                .elementThree {
                
                    min-width: 72vw;
                    flex-grow: 2;
                    display: flex;
                    flex-direction: column;
                    background-color: #FF4700;
                    ul{
                        margin: 0;
                        padding: 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        width: 100%;
                        min-height: 40%;
                        animation: textodesaparece 2s;
                        li{
                            
                            cursor: pointer;
                            width: 80%;
                            margin-left: 10%;                        
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            border-bottom: 1px solid #FFF;
                            list-style: none;
                            color: #FFF;
                            font-size: 1rem;
                            font-family: Montserrat;
                            min-height: 20%;
                            font-weight: 600;
                            display: none;
                            .Links{
                                font-size: 2rem;                           
                            }
                            
                        }
                        li:hover {
                            .Links{
                                color:#FFF;
                            }
                        }

                        margin: 0;
                        margin-top: 20%;
                    }            
                    @keyframes textodesaparece {
                        0%{
                            display: flex;
                            font-size: 1rem;
                        }
                        100%{
                            display: none;
                            font-size: 0rem;
                        }
                    }
                    div{
                        width: 90%;
                        
                        margin: 0;
                        padding: 0;
                        display: flex;
                        flex-direction:row;
                        justify-content: flex-start;
                        margin-left: 10%;

                        .imagen{
                            margin: 0px;
                            margin-right: 5vw;
                            min-width: 10vw;
                            max-width: 10vw;                       
                            padding: 0;
                        }
                        
                    }
                }
            }
        }
        .HeaderNavContainer{      
            .navigationMenu{     
                .MenuBottoms{            
                    .containerTBE{                  
                        background-color: #FF4700;
                        min-width: 15vw;                     
                    }
                    .containerLinks{
                        background-color: #FF4700;                 
                    }
                    .containerBotton{
                        background-color: #FF4700;                   
                        min-width: 15vw;
                    }
                }
            }

        }
        .HeaderNavContainerWhite{      
            .navigationMenu{     
                .MenuBottoms{            
                    .containerTBE{                  
                        background-color: #FFF;
                        min-width: 15vw;  
                    }
                    .containerLinks{
                        background-color: #FFF;                 
                    }
                    .containerBotton{
                        background-color: #FFF;  
                        min-width: 15vw;                 
                    }
                }
            }

        }
        .HeaderNavContainerBlack{      
            .navigationMenu{     
                .MenuBottoms{            
                    .containerTBE{                  
                //      background-color: #000;  
                        min-width: 15vw;               
                    }
                    .containerLinks{
                    //    background-color: #000;                 
                    }
                    .containerBotton{
                    //   background-color: #000; 
                        min-width: 15vw;                  
                    }
                } 
            }

        }


        .MenuTitleHeaders{
            min-height: 92vh;
            display: flex;
            flex-direction: row;
            div{
                width: 30vw;
                background-color: #FF4700;
            }
    //background-color: rgba( 0,0,0,  0.4);
            .elementThree {
                min-width: 70vw;
                flex-grow: 2;
                display: flex;
                flex-direction: column;
                ul{
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    width: 100%;
                    min-height: 95%;
                    li{
                        cursor: pointer;
                        width: 80%;
                        margin-left: 10%;                        
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        border-bottom: 1px solid #FFF;
                        list-style: none;
                        color: #FFF;
                        font-size: 1rem;
                        font-family: Montserrat;
                        min-height: 20%;
                        font-weight: 600;
                        .Links{
                            font-size: 0.8rem;
                        }
                    }
                    li:hover{
                        color: #FFF;
                    }
                    margin: 0;
                    margin-top: 20%;
                }            
                div{
                    width: 90%;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-direction:row;
                    justify-content: flex-start;
                    margin-left: 10%;
                    .imagen{
                        margin: 0px;
                        margin-right: 5vw;
                        min-width: 10vw;
                        max-width: 10vw;                       
                        padding: 0;
                        
                    }
                
                }
            }
        }


        /*      HEADER CLOSED       */
        
        .HeaderNavContainerOpen,  .HeaderNavContainerOpenWhite,  .HeaderNavContainerOpenBlack{
            z-index: 50;
            min-width: 100vw;
            max-width: 100vw;
            min-height: 8vh;
            display: flex;
            flex-direction: column;
            background-color: #FF4700;
        //  background-color: rgba( 0,0,0,  0.4);
            .navigationMenu{
                min-width: 100vw;
                max-width: 100vw;
                background-color: #FF4700;
                .MenuBottoms{
                    display: flex;
                    flex-direction: row;
                    min-height: 8vh;             
                    .containerTBE{
                        background-color: transparent;
                        cursor: pointer;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        min-height: 8vh;
                        min-width: 28vw;
                        .LinkLogo{
                            max-width: 10vw;                                           
                            margin-right: 13vw;
                        }
                        .LinkLogo >  svg{
                            width: 15vw;
                        }
                    }
                    .containerLinks{
                        background-color: #FF4700;
                        display: flex;
                        justify-content: flex-end;                
                        align-items: center;
                        padding-right: 5vw;
                        background-color: none;
                        img{
                            margin-left: 2vw;
                            min-width: 2vw;
                        }
                    }
                    .containerBotton{
                background-color: #FF4700;

                        display: flex;                    
                        justify-content: flex-start;
                        align-items: center;
                        min-width: 15vw;
                        
                        color: #FFF;
                    }
                }
            }
            .MenuTitleHeaders{
                min-height: 92vh;
                display: flex;
                flex-direction: row;
                div{
                    width: 30vw;
                }
                .elementThree {
                    min-width: 72vw;
                    flex-grow: 2;
                    display: flex;
                    flex-direction: column;
                    background-color: #FF4700;
                    ul{
                        margin: 0;
                        padding: 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        width: 100%;
                        min-height: 40%;
                        li{
                            cursor: pointer;
                            width: 80%;
                            margin-left: 10%;                        
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            border-bottom: 1px solid #FFF;
                            list-style: none;
                            color: #FFF;
                            font-size: 1rem;
                            font-family: Montserrat;
                            min-height: 20%;
                            font-weight: 600;
                            .Links{
                                font-size: 2rem;
                            }
                            .Links:hover{
                                font-size: 2rem;
                                color: #FFF;
                            }
                        }
                        li:hover{
                            color: #FFF;
                            animation: none;
                        }
                        margin: 0;
                        margin-top: 20%;
                    }            
                    div{
                        width: 90%;
                        
                        margin: 0;
                        padding: 0;
                        display: flex;
                        flex-direction:row;
                        justify-content: flex-start;
                        margin-left: 10%;
                        .imagen{
                            display: none;
                        }
                        .imagenMovil{
                            display: flex;
                            margin: 0px;
                            margin-right: 5vw;
                            min-width: 10vw;
                            max-width: 10vw;                       
                            padding: 0;
                            img{
                                width: 150%;
                            }
                        }
                        
                    }
                }
            }
        }
    }

/* Phones Horizontales */


@media (max-width: 1000px) and (orientation: landscape) {
    @keyframes MenuContener {
        0% {
            min-height:  0vh;
            max-height:  0vh;
        }
        
        100% {
            min-height:  93vh;
            max-height:  93vh;
        }
    }
    
    .HeaderNavContainer, .HeaderNavContainerWhite, .HeaderNavContainerBlack{
        z-index: 50;
        min-width: 100vw;
        max-width: 100vw;
        min-height: 8vh;
        display: flex;
        flex-direction: column;
        background-color: #FF4700;
        .navigationMenu{
            min-width: 100vw;
            max-width: 99.7vw;
            background-color: #FF4700;
            .MenuBottoms{               
                display: flex;
                flex-direction: row;
                min-height: 8vh;             
                .containerTBE{
                    background-color: transparent;
                    cursor: pointer;
                    display: flex;
                    margin: 0;
                    align-items: center;
                    justify-content: center;
                    min-height: 8vh;
                    min-width: 30vw;
                    width: 20vw;
                    .LinkLogo{
                        max-width: 10vw;     
                    }
                    .LinkLogo >  svg{
                        width: 12vw;
                    }
                }
                .containerLinks{
                    background-color: #FF4700;
                    display: flex;
                    justify-content: flex-end;                
                    align-items: center;
                    padding-right: 5vw;
                    background-color: none;
                    .imagen{
                        margin-right: 10vw;
                    }
                }
                .containerBotton{
                    background-color: #FF4700;
                    display: flex;                    
                    justify-content: flex-start;
                    align-items: center;
                    min-width: 15vw;                    
                    color: #FFF;
                }
            }
        }
        .MenuTitleHeaders{
            min-height: 0vh;
            display: flex;
            flex-direction: row;
            div{
                width: 30vw;
            }
            .elementThree {
               
                min-width: 72vw;
                flex-grow: 2;
                display: flex;
                flex-direction: column;
                background-color: #FF4700;
                ul{
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    width: 100%;
                    min-height: 40%;
                    animation: textodesaparece 2s;
                    li{
                        
                        cursor: pointer;
                        width: 80%;
                        margin-left: 10%;                        
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        border-bottom: 1px solid #FFF;
                        list-style: none;
                        color: #FFF;
                        font-size: 1rem;
                        font-family: Montserrat;
                        min-height: 20%;
                        font-weight: 600;
                        display: none;
                        .Links{
                            font-size: 2rem;
                           // display: hidden;
                            //display: none;
                        }
                    }
                   
                    margin: 0;
                    margin-top: 20%;
                }            
                @keyframes textodesaparece {
                    0%{
                        display: flex;
                        font-size: 1rem;
                    }
                    100%{
                        display: none;
                        font-size: 0rem;
                    }
                }
                div{
                    width: 90%;
                    
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-direction:row;
                    justify-content: flex-start;
                    margin-left: 10%;
                    .imagen{
                        margin: 0px;
                        margin-right: 5vw;
                        min-width: 10vw;
                        max-width: 10vw;                       
                        padding: 0;
                        
                    }
                    
                }
            }
        }
    }
    .HeaderNavContainer{      
        .navigationMenu{     
            .MenuBottoms{            
                .containerTBE{      
                    background-color: #FF4700;
                    min-width: 14vw;    
                    max-width: 14vw;                     
                }
                .containerLinks{
                    background-color: #FF4700;    
                    padding: 0;
                    .imagen{
                        margin: 0;
                        margin-right: 5vw;
                    }
                }
                .containerBotton{
                    margin-right: 2vw;
                    background-color: #FF4700;                   
                    min-width: 10vw;
                    padding: 0;
                    margin: 0;
                    display: flex;
                    justify-content: flex-end;
                    padding-right: 4vw;

                }
            }
        }

    }
    .HeaderNavContainerWhite{      
        .navigationMenu{     
            .MenuBottoms{            
                .containerTBE{        
                    background-color: #FFF;
                    min-width: 15vw;  
                }
                .containerLinks{
                    background-color: #FFF;                 
                }
                .containerBotton{
                    background-color: #FFF;  
                    min-width: 15vw;                 
                }
            }
        }

    }
    .HeaderNavContainerBlack{      
        .navigationMenu{     
            .MenuBottoms{            
                .containerTBE{                  
                    min-width: 15vw;               
                }
                .containerLinks{
                }
                .containerBotton{
                    min-width: 15vw;                  
                }
            } 
        }

    }


    .MenuTitleHeaders{
        min-height: 92vh;
        display: flex;
        flex-direction: row;
        div{
            width: 30vw;
            background-color: #FF4700;
        }
        .elementThree {
            min-width: 70vw;
            flex-grow: 2;
            display: flex;
            flex-direction: column;
            ul{
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                width: 100%;
                min-height: 95%;
                li{
                    cursor: pointer;
                    width: 80%;
                    margin-left: 10%;                        
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    border-bottom: 1px solid #FFF;
                    list-style: none;
                    color: #FFF;
                    font-size: 1rem;
                    font-family: Montserrat;
                    min-height: 20%;
                    font-weight: 600;
                    .Links{
                        font-size: 0.8rem;
                    }
                }
                li:hover{
                    color: black;
                }
                margin: 0;
                margin-top: 20%;
            }            
            div{
                width: 90%;
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction:row;
                justify-content: flex-start;
                margin-left: 10%;
                .imagen{
                    margin: 0px;
                    margin-right: 5vw;
                    min-width: 10vw;
                    max-width: 10vw;                       
                    padding: 0;
                    
                }
                
            }
        }
    }


    /*      HEADER CLOSED       */
    
    .HeaderNavContainerOpen,  .HeaderNavContainerOpenWhite,  .HeaderNavContainerOpenBlack{
        z-index: 50;
        min-width: 99.7;
        max-width: 99.7;
        min-height: 8vh;
        display: flex;
        flex-direction: column;
        background-color: transparent;
        .navigationMenu{
            min-width: 99.7vw;
            max-width: 99.7vw;
            background-color: transparent;
            .MenuBottoms{
                display: flex;
                flex-direction: row;
                min-height: 8vh;   
                border: 0px solid #FF4700;          
                .containerTBE{
                    background-color: transparent;
                    cursor: pointer;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    min-height: 8vh;
                    min-width: 28vw;
                    border: 1px solid #FF4700;
                    background-color: #FF4700;
                    .LinkLogo{
                        max-width: 10vw;                                           
                        margin-right: 13vw;
                        background-color: transparent;
                    }
                    .LinkLogo >  svg{
                        width: 12vw;
                    }
                }
                .containerLinks{
                    display: flex;
                    justify-content: flex-end;                
                    align-items: center;
                    padding-right: 5vw;
                    background-color: none;
                    background-color: transparent;
                    border: 1px solid #FF4700;
                    background-color: #FF4700;
                    img{
                        margin-left: 2vw;
                        min-width: 2vw;
                    }
                }
                .containerBotton{
                    border: 1px solid #FF4700;
                    background-color: #FF4700;
                    display: flex;                    
                    justify-content: flex-start;
                    align-items: center;
                    min-width: 9vw;                    
                    color: #FFF;
                }
            }
        }
        .MenuTitleHeaders{
            border: none;
            min-height: 92vh;
            display: flex;
            flex-direction: row;
            div{
                border: none;
                width: 30vw;
            }
            .elementThree {
                min-width: 72vw;
                flex-grow: 2;
                display: flex;
                flex-direction: column;
                background-color: #FF4700;
                ul{
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    width: 100%;
                    min-height: 40%;
                    li{
                        cursor: pointer;
                        width: 77%;
                        margin-left: 10%;                        
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        border-bottom: 1px solid #FFF;
                        list-style: none;
                        color: #FFF;
                        font-size: 1rem;
                        font-family: Montserrat;
                        min-height: 20%;
                        font-weight: 600;
                        .Links{
                            font-size: 2rem;
                        }
                    }
                    li:hover{
                        color: white;
                        animation: none;
                    }
                    margin: 0;
                    margin-top: 20%;
                }            
                div{
                    width: 90%;                    
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-direction:row;
                    justify-content: flex-start;
                    margin-left: 10%;
                    background-color: transparent;
                    .imagen{
                        margin: 0px;
                        margin-right: 5vw;
                        min-width: 10vw;
                        max-width: 10vw;                       
                        padding: 0;
                        background-color: transparent;
                    }                    
                }
            }
        }
    }
}

















/* CELULARES < 400 px*/

@media (max-width: 500px)and(orientation: portrait) {
        @keyframes MenuContener {
            0% {
                min-height:  0vh;
                max-height:  0vh;
            }
            
            100% {
                min-height:  93vh;
                max-height:  93vh;
            }
        }
        
        .HeaderNavContainer, .HeaderNavContainerWhite, .HeaderNavContainerBlack{
            z-index: 150;
            min-width: 100vw;
            max-width: 100vw;
            min-height: 8vh;
            display: flex;
            flex-direction: column;
            background-color: none;
            .navigationMenu{
                min-width: 100vw;
                max-width: 100vw;
                background-color: none;

                .MenuBottoms{
                    display: flex;
                    flex-direction: row;
                    min-height: 8vh;             

                    .containerTBE{
                        background-color: transparent;
                        cursor: pointer;
                        display: flex;
                        margin: 0;
                        align-items: center;
                        justify-content: flex-end;
                        min-height: 8vh;
                        min-width: 30vw;
                        width: 20vw;
                        .LinkLogo{
                            max-width: 15vw;     
                        }
                        .LinkLogo >  svg{
                            width: 15vw;
                        }
                    }
                    .containerLinks{
                        background-color: #FF4700;
                        display: flex;
                        justify-content: flex-end;                
                        align-items: center;
                        padding-right: 3vw;
                        min-width: 60;
                        max-width: 60vw;
                        background-color: none;
                        .imagen{
                            display: none;
                            margin-right: 10vw;
                        }
                        .imagenMovil{
                            display: flex;
                            margin-right: 10vw;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 0;
                            img{
                                width: 75%;
                            }
                        }
                    }
                    .containerBotton{
                        background-color: #FF4700;
                        display: flex;                    
                        justify-content: flex-start;
                        align-items: center;
                        min-width: 20vw;
                        margin-left: 0vw;
                        width: 20vw ;
                        max-width: 20vw;                        
                        color: #FFF;
                    
                    }
                }
            }
            .MenuTitleHeaders{
                min-height: 0vh;
                display: flex;
                flex-direction: row;
                div{
                    width: 30vw;
                }
                .elementThree {
                
                    min-width: 72vw;
                    flex-grow: 2;
                    display: flex;
                    flex-direction: column;
                    background-color: #FF4700;
                    ul{
                        margin: 0;
                        padding: 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        width: 100%;
                        min-height: 40%;
                        animation: textodesaparece 2s;
                        li{
                            
                            cursor: pointer;
                            width: 80%;
                            margin-left: 10%;                        
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            border-bottom: 1px solid #FFF;
                            list-style: none;
                            color: #FFF;
                            font-size: 1rem;
                            font-family: Montserrat;
                            min-height: 20%;
                            font-weight: 600;
                            display: none;
                            .Links{
                                font-size: 2rem;                           
                            }
                            
                        }
                        li:hover {
                            .Links{
                                color:#FFF;
                            }
                        }

                        margin: 0;
                        margin-top: 20%;
                    }            
                    @keyframes textodesaparece {
                        0%{
                            display: flex;
                            font-size: 1rem;
                        }
                        100%{
                            display: none;
                            font-size: 0rem;
                        }
                    }
                    div{
                        width: 90%;
                        
                        margin: 0;
                        padding: 0;
                        display: flex;
                        flex-direction:row;
                        justify-content: flex-start;
                        margin-left: 10%;

                        .imagen{
                            margin: 0px;
                            margin-right: 5vw;
                            min-width: 10vw;
                            max-width: 10vw;                       
                            padding: 0;
                        }
                        
                    }
                }
            }
        }
        .HeaderNavContainer{      
            .navigationMenu{     
                .MenuBottoms{            
                    .containerTBE{                  
                        background-color: #FF4700;
                        min-width: 15vw;                     
                    }
                    .containerLinks{
                        background-color: #FF4700;                 
                    }
                    .containerBotton{
                        background-color: #FF4700;                   
                        min-width: 15vw;
                    }
                }
            }

        }
        .HeaderNavContainerWhite{      
            .navigationMenu{     
                .MenuBottoms{            
                    .containerTBE{                  
                        background-color: #FFF;
                        min-width: 15vw;  
                    }
                    .containerLinks{
                        background-color: #FFF;                 
                    }
                    .containerBotton{
                        background-color: #FFF;  
                        min-width: 15vw;                 
                    }
                }
            }

        }
        .HeaderNavContainerBlack{      
            .navigationMenu{     
                .MenuBottoms{            
                    .containerTBE{                  
                //      background-color: #000;  
                        min-width: 15vw;               
                    }
                    .containerLinks{
                    //    background-color: #000;                 
                    }
                    .containerBotton{
                    //   background-color: #000; 
                        min-width: 15vw;                  
                    }
                } 
            }

        }


        .MenuTitleHeaders{
            min-height: 92vh;
            display: flex;
            flex-direction: row;
            div{
                width: 30vw;
                background-color: #FF4700;
            }
    //background-color: rgba( 0,0,0,  0.4);
            .elementThree {
                min-width: 70vw;
                flex-grow: 2;
                display: flex;
                flex-direction: column;
                ul{
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    width: 100%;
                    min-height: 95%;
                    li{
                        cursor: pointer;
                        width: 80%;
                        margin-left: 10%;                        
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        border-bottom: 1px solid #FFF;
                        list-style: none;
                        color: #FFF;
                        font-size: 1rem;
                        font-family: Montserrat;
                        min-height: 20%;
                        font-weight: 600;
                        .Links{
                            font-size: 0.8rem;
                        }
                    }
                    li:hover{
                        color: #FFF;
                    }
                    margin: 0;
                    margin-top: 20%;
                }            
                div{
                    width: 90%;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-direction:row;
                    justify-content: flex-start;
                    margin-left: 10%;
                    .imagen{
                        margin: 0px;
                        margin-right: 5vw;
                        min-width: 10vw;
                        max-width: 10vw;                       
                        padding: 0;
                        
                    }
                
                }
            }
        }


        /*      HEADER CLOSED       */
        
        .HeaderNavContainerOpen,  .HeaderNavContainerOpenWhite,  .HeaderNavContainerOpenBlack{
            z-index: 250;
            min-width: 100vw;
            max-width: 100vw;
            min-height: 8vh;
            display: flex;
            flex-direction: column;
            background-color: #FF4700;
        //  background-color: rgba( 0,0,0,  0.4);

            .navigationMenu{
                min-width: 100vw;
                max-width: 100vw;
                background-color: #FF4700;
                .MenuBottoms{
                    display: flex;
                    flex-direction: row;
                    min-height: 8vh;    

                    .containerTBE{
                        background-color: transparent;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 8vh;
                        min-width: 28vw;
                        max-width: 28vw;

                        .LinkLogo{
                            max-width: 16vw;                                           
                            margin-left: 16vw;
                        }
                        .LinkLogo >  svg{
                            width: 15vw;
                        }
                    }
                    .containerLinks{
                        background-color: #FF4700;
                        display: flex;
                        justify-content: flex-end;                
                        align-items: center;
                        padding-right: 5vw;
                        background-color: none;
                        img{
                            margin-left: 2vw;
                            min-width: 2vw;
                        }
                    }
                    .containerBotton{
                        background-color: #FF4700;
                        display: flex;                    
                        justify-content: flex-start;
                        align-items: center;
                        min-width: 18vw;
                        color: #FFF;
                    }
                }
            }
            .MenuTitleHeaders{
                min-height: 92vh;
                display: flex;
                flex-direction: row;
                div{
                    width: 30vw;
                }
                .elementThree {
                    min-width: 72vw;
                    flex-grow: 2;
                    display: flex;
                    flex-direction: column;
                    background-color: #FF4700;
                    ul{
                        margin: 0;
                        padding: 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        width: 100%;
                        min-height: 40%;
                        li{
                            cursor: pointer;
                            width: 80%;
                            margin-left: 10%;                        
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            border-bottom: 1px solid #FFF;
                            list-style: none;
                            color: #FFF;
                            font-size: 1rem;
                            font-family: Montserrat;
                            min-height: 20%;
                            font-weight: 600;
                            .Links{
                                font-size: 2rem;
                            }
                            .Links:hover{
                                font-size: 2rem;
                                color: #FFF;
                            }
                        }
                        li:hover{
                            color: #FFF;
                            animation: none;
                        }
                        margin: 0;
                        margin-top: 20%;
                    }            
                    div{
                        width: 90%;
                        
                        margin: 0;
                        padding: 0;
                        display: flex;
                        flex-direction:row;
                        justify-content: flex-start;
                        margin-left: 10%;
                        .imagen{
                            display: none;
                        }
                        .imagenMovil{
                            display: flex;
                            margin: 0px;
                            margin-right: 5vw;
                            min-width: 10vw;
                            max-width: 10vw;                       
                            padding: 0;
                            img{
                                width: 150%;
                            }
                        }
                        
                    }
                }
            }
        }
    }

