.containerContentPageTwo{
    margin: 0;
    z-index: 50;
    min-width: 100vw;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity(0.1s);   
    div{
        background-color: transparent;
    } 
    .containerSubContentPageTwo{
    }
}

.titlePageTwo{
    z-index: 50;
    width: 90vw;
    min-height: 20vh;
    max-height: 20vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #FFF;
    font-family:  Montserrat;
    font-size: 1rem;
    img{
        margin-right: 1%;
    }
}
.containerSubContentPageTwo{
    min-width:  90vw;
    z-index: 50;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    .subtitleContainerPageTwo{
        min-width: 30vw;
        max-width: 30vw;
        margin-left: 4vw;
        text-align: left;
        color: #FFF;
        font-family:  Montserrat;       
        font-size: 3rem;
    }
}
.optionSelectSubtitle{
    margin-top: 1%;
    min-width:  85vw;
    display: flex;
    flex-direction: row;
    .submenuPageTwo{
        text-align: left;
        min-width: 52.5%;
        height: 40vh;
        display: flex;        
        align-items: flex-start;
        font-family:  Montserrat;
        .submenu{
            padding-top: 5vh;
            width: 80%;
            list-style: none;
            color: #707070;
            font-size: 2.3rem;
            font-weight: 800;
            font-family:  Montserrat;
            li{
                cursor: pointer;
                display: flex;
                align-items: center;
                margin-left:10%;
                width: 90%;
                min-height: 5.5vh;
                min-height: 5vh;

                text{
                    width: 80%;
                    min-height: 5vh;
                    display: flex;
                    align-items: center;
                    border-bottom: 0.5px solid rgba(255,255,255, 0.2);
                }

                
                background-image:   linear-gradient(to right, #C9C9C9 100%, #C9C9C9 100%);
                background-size:    100% 100%;//,
                background-position:   0% 99%;

                background-repeat: no-repeat;
                background-image:   linear-gradient(to right, #C9C9C9 100%, #C9C9C9 100%);
                background-size:    80% 1%;//,
                background-position:   0% 99%;          
            }

            li:hover{
                background-size:    80% 1.5px;
                animation: TitleHover 1.25s cubic-bezier(0.19, 1, 0.22, 1) 1;
            }
            @keyframes TitleHover {
                0% {
                    background-size:  0% 1.5px,
                }                                       
                100% {
                    background-size:    80% 1.5px,                                        
                }
            }
            .suboptionSelected{
                color: #FA500F;
                background-size:    80% 1.5px;
            }
        }
    }
    .contentMenuPageTwo{
        min-width: 25%;
        max-width: 25%;
        display: flex;
        padding-top: 5vh;
        .textContainer{
                .title{
                    color: #FFF;
                    font-size: 1.4rem;
                    font-family: Montserrat;
                    margin-bottom: 3%;
                }
                .subtitle{
                    color: #FA500F;
                    font-size: 1.2rem;
                    font-family: Montserrat;
                    margin-bottom: 3%;
                }
                .text{                    
                    max-width: 25vw;
                    color: #CBCBCB;
                    font-size: 1rem;
                    font-weight: 400;
                    text-align: justify;
                    font-family: Montserrat;
                   // line-height: 150%;
                }
                .Links{
                    color: #FFF;
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-end;
                    min-height: 10vh;
                    font-size: 1.3rem;
                    font-family: Montserrat;
                    width: 100%;
                  //  border: 1px solid yellow;
                    border-bottom: 0.2px solid rgba(255,255,255, .3);
                    .boton{
                        display: flex;
                        width: 100%;
                        min-height: 4vh;
                        margin-bottom: 0;
                        text{
                            //: 1px solid red;
                            width: 95%;
                            font-size: 1.2rem;
                            font-weight: 400;
                           // border-bottom: 1px solid rgba(255,255,255, .3);
                        }
                    }
                    background-image:   linear-gradient(to right, #C9C9C9 100%, #C9C9C9 100%);
                    background-size:    90% 100%;//,
                    background-position:   0% 100%;
    
                    background-repeat: no-repeat;
                    background-image:   linear-gradient(to right, #C9C9C9 100%, #C9C9C9 100%);
                    background-size:    100% 0.5%;//,
                    background-position:   0% 100%;          
                }
                .Links:hover{             
                    color: #FA500F;
                    background-size:    100% 1.5px;     
                    animation: TitleHoverSubPageTwo 1.25s cubic-bezier(0.19, 1, 0.22, 1) 1;            
                }
                @keyframes TitleHoverSubPageTwo {
                    0% {
                        background-size:  0% 1.5px;
                        color: #FFF;
                    }                                       
                    100% {
                        background-size:    100% 1.5px;
                        color: #FA500F;
                    }
                }
        }
       
    }
}

.Links{
    text-decoration: none;    
    padding-bottom: 10px;
}

.textContainerDesplegado{
    display: none;
}


.optionSelectSubtitleMovil{
    display: none;
}











