.GeneralContainer{   
    max-width: 100vw;
    min-height: 800vh;
    margin: 0;
    padding: 0;                            
    display: flex;
    align-items: center;
    flex-direction: column;
}

.containerHeaderNav{   
    margin: 0;
    padding: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 7vh;
    max-height: 7vh;
    display: flex;
    flex-direction: row;
    
    .containerHeaderLogo{       
        width: 70%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;  
        img{
            margin-top: 3%;
            margin-left: 5%;
        }
    }
    .containerLinksSocial{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 20vw;    
        padding-right: 2%;
        img{
            margin-left: 10%;
        }
    }
    .containerMenu{
        width: 10vw;   
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        img{
            margin-top: 15%;
            margin-right: 45%;
        }
    }
}



.iconsBottoms{
    min-width: 80%;
    min-height: 20%;
    max-height: 20%;
    display: flex;
    justify-content: center;
    margin-top:20px;
    img{
        margin-right:30px;
        margin-right:30px;
    }
}


.containerProjects{
    margin:0;
    min-width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
    background-color: white;
    display: flex;  
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.containerContact{
    margin:0;
    min-width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
    background-color: white;
    display: flex;  
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.containerHeaderNavShow{
    min-width: 100%;
    max-width: 100%;
    min-height: 40vh;
    opacity: 0.8;
    position: absolute;
    margin-top:0;
    display: flex;
    justify-content: center;
    flex-direction: row;

    .containerHeaderLogo{
        min-width: 60vw;
        max-width: 60vw;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0;
        padding: 0;        
        img{
            color: #FFF;
            margin: 0;
            width: 7%;
            margin-left: 10%;
            margin-top: 3%;
        }
    }
    .containerHeaderCentral{
        min-width: 35vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .containerMenu{  
            padding-left: 10%;
            color: #FFF;
            min-height: 60%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            ul{
                list-style: none;
                font-size:2.8rem;
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
                .enlace{
                    color: #FFF;
                    list-style: none;
                    text-decoration:none;
                    font-family: Montserrat;
                    border-bottom: 1px solid #848484;
                }
               
            }
        }
        .containerLinksSocial{
            padding-left: 10%;
            min-height: 20%;
            display: flex;
            justify-content:flex-start;
            align-items: center;
            img{
                width: 6%;
                margin-right: 60px;
            }
        }
    }
    .containerHeaderClose{
        min-width: 10vw;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        img{
            width: 20%;
            margin-left: 5%;
            margin-top: 10%;
        }
    }
}
