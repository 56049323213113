.containerTransicion{
    min-width: 25vw;
    max-width: 25vw;
    min-height: 40vh;
    max-height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .Imagen1{
        position: absolute;
        min-width: 22vw;
        max-width: 22vw;
       
    }

    .NuevoTamaño1{
        margin-left: 10vw;
        margin-top: 5vh;
        position: absolute;
        min-width: 45vw;
        max-width: 45vw;
       
    }
    @keyframes AnimacionUno {
        0%{
            opacity: 1;
        }
        50%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }        
    }
   

    .Imagen2{
        position: absolute;  
        z-index: 20;
        min-width: 22vw;
        max-width: 22vw;
        animation: AnimacionUno 4s infinite;
    }



    .NuevoTamaño2{
        margin-top: 5vh;
        position: absolute;  
        z-index: 20;
        margin-left: 10vw;
        min-width: 45vw;
        max-width: 45vw;
        animation: AnimacionUno 4s infinite;
    }



}



