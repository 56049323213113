



$animationSpeed: 30s;

// Animation
@keyframes scrollAdelante {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(+80vw * 3))}
}


// Styling
.sliderTwo {
	background-color: none;
	min-width: 80vw;
	min-height: 60vh;
	max-height: 60vh;
	margin: 0;
	overflow:hidden;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	
	&::after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

	&::before {
		left: 0;
		top: 0;
	}
	
	.slide-track {
		animation: scrollAdelante $animationSpeed linear infinite;
		display: flex;
		width: calc(80vw * 6);
	}
	.slide-track:hover {
		animation-play-state: paused;
	}
	
	.slide {
		min-height: 50vh;
		min-width: 80vw;
		display: flex;
		flex-direction: row;
		padding: 0;
		//margin-right: 5vw;
		display: flex;
		justify-content: center;
		align-items: center;
		.contentOne, .contentTwo{
			display: flex;
			min-width: 40vw;
			min-height: 50vh;
			justify-content: center;
			align-items: center;
		}
			
	

		.contentOne{
			.contentOneLeft{
				min-width: 40%;
				min-height: 100%;
				display: flex;
				justify-content: flex-end;
				align-items: flex-start;
				.Foto1{
					margin-top: 30%;
					min-width:  7vw;
					min-height: 7vw;
					background-image: url('../../img//pagesix/Logo1.png');
					background-size: 100%;
					background-repeat: no-repeat;
				}
				.Foto2{
					margin-top: 30%;
					min-width:  7vw;
					min-height: 7vw;
					background-image: url('../../img//pagesix/Logo2.png');
					background-size: 100%;
					background-repeat: no-repeat;
				}
				.Foto3{
					margin-top: 30%;
					min-width:  7vw;
					min-height: 7vw;
					background-image: url('../../img//pagesix/Logo3.png');
					background-size: 100%;
					background-repeat: no-repeat;
				}
				.Foto4{
					margin-top: 30%;
					min-width:  7vw;
					min-height: 7vw;
					background-image: url('../../img//pagesix/Logo4.png');
					background-size: 100%;
					background-repeat: no-repeat;
				}
				.Foto5{
					margin-top: 30%;
					min-width:  7vw;
					min-height: 7vw;
					background-image: url('../../img//pagesix/Logo5.png');
					background-size: 100%;
					background-repeat: no-repeat;
				}
				.Foto6{
					margin-top: 30%;
					min-width:  7vw;
					min-height: 7vw;
					background-image: url('../../img//pagesix/Logo6.png');
					background-size: 100%;
					background-repeat: no-repeat;
				}
				
			}
			.contentOneRight{
				min-width: 40%;
				max-width: 40%;
				min-height: 100%;							
				text{
					margin-left: 1vw;
					display: flex;
					flex-direction: column;
					.Title{
						margin-top: 30%;
						font-size: 1.2rem;
						color: #FF4700;
						font-family: Montserrat;
					}
					.Content{
						margin-top: 0.5vw;
						line-height: 1vw;
						font-size: 1rem;
						color: white;
						font-family: Montserrat;
					}.Client{
						margin-top: 0.5vw;
						line-height: 1vw;
						font-size: 0.9rem;
						color: white;
						font-family: Montserrat;
					}

				}
			}
		}		
	}
}


























@media (max-width: 700px){





$animationSpeed: 20s;

// Animation
@keyframes scrollAdelante {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(+80vw * 6))}
}


// Styling
.sliderTwo {

	
	&::after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

	&::before {
		left: 0;
		top: 0;
	}
	
	.slide-track {
		animation: scrollAdelante $animationSpeed linear infinite;
		display: flex;
		width: calc(80vw * 12);
	}
	
	.slide {
		min-width: 80vw;
		.contentOne, .contentTwo{
			min-width: 80vw;
		}
			
		.contentOne{
			padding-top: 10vh;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			.contentOneLeft{
				margin: 0;
				min-width: 40vw;
				min-height: 20%;
				display: flex;
				justify-content: flex-end;
				align-items: flex-end;
				margin-top: 5vh;
				padding: 0;
				.Foto1,.Foto2,.Foto3,.Foto4,.Foto5,.Foto6{
					margin: 0;
					min-width: 40vw;
					min-height: 20vw;
					background-position: left;
				}				
			}
			.contentOneRight{
				min-width: 40vw;
				min-height: 60%;
				padding-top: 0;
				margin: 0;
				display: flex;
				text{					
					padding: 0;
					margin: 0;
					display: flex;
					justify-content: flex-start;
					align-items: flex-start;
					.Title{
						margin: 0;
						min-width: 100%;
						margin-bottom: 10px;
						font-size: 1.2rem;
						text-align: center;
					}
					.Content{
						min-width: 100%;
						margin-bottom: 10px;
						font-size: 1rem;
						line-height: 1rem;
						text-align: left;
					}
					.Client{
						min-width: 100%;
						margin-bottom: 10px;
						font-size: 0.9rem;
						line-height: 1rem;
					}

				}
			}
		}
	



		.contentTwo{
			display: flex;
			flex-direction: column-reverse;

			.contentTwoLeft{
				min-width: 40vw;
				min-height: 60%;					
				text{					
					.Title{

						min-width: 100%;
						margin-bottom: 10px;
						font-size: 1.2rem;
					}
					.Content{
						min-width: 100%;
						margin-bottom: 10px;
						font-size: 1rem;
						line-height: 1rem;
					}
					.Client{
						min-width: 100%;
						margin-bottom: 10px;
						font-size: 0.9rem;
						line-height: 1rem;
					}

				}
			}
			.contentTwoRight{
				min-width: 40vw;
				min-height: 20%;
				display: flex;
				justify-content: center;
				align-items: flex-start;
				margin: 0;
				padding: 0;
				.Foto1,.Foto2,.Foto3,.Foto4,.Foto5,.Foto6{
					margin: 0;
					min-width: 40vw;
					min-height: 20vw;
				}
			}
		}

	}
}

}











@media (max-width: 700px){





$animationSpeed: 20s;

// Animation
@keyframes scrollAdelante {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(+80vw * 6))}
}


// Styling
.sliderTwo {

	
	&::after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

	&::before {
		left: 0;
		top: 0;
	}
	
	.slide-track {
		animation: scrollAdelante $animationSpeed linear infinite;
		display: flex;
		width: calc(80vw * 12);
	}
	
	.slide {
		min-width: 80vw;
		.contentOne, .contentTwo{
			min-width: 80vw;
		}
			
		.contentOne{
			padding-top: 10vh;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			.contentOneLeft{
				margin: 0;
				min-width: 40vw;
				min-height: 20%;
				display: flex;
				justify-content: flex-end;
				align-items: flex-end;
				margin-top: 5vh;
				padding: 0;
				.Foto1,.Foto2,.Foto3,.Foto4,.Foto5,.Foto6{
					margin: 0;
					min-width: 40vw;
					min-height: 20vw;
					background-position: left;
				}				
			}
			.contentOneRight{
				min-width: 40vw;
				min-height: 60%;
				padding-top: 0;
				margin: 0;
				display: flex;
				text{					
					padding: 0;
					margin: 0;
					display: flex;
					justify-content: flex-start;
					align-items: flex-start;
					.Title{
						margin: 0;
						min-width: 100%;
						margin-bottom: 10px;
						font-size: 1.2rem;
						text-align: center;
					}
					.Content{
						min-width: 100%;
						margin-bottom: 10px;
						font-size: 1rem;
						line-height: 1rem;
						text-align: left;
					}
					.Client{
						min-width: 100%;
						margin-bottom: 10px;
						font-size: 0.9rem;
						line-height: 1rem;
					}

				}
			}
		}
	



		.contentTwo{
			display: flex;
			flex-direction: column-reverse;

			.contentTwoLeft{
				min-width: 40vw;
				min-height: 60%;					
				text{					
					.Title{

						min-width: 100%;
						margin-bottom: 10px;
						font-size: 1.2rem;
					}
					.Content{
						min-width: 100%;
						margin-bottom: 10px;
						font-size: 1rem;
						line-height: 1rem;
					}
					.Client{
						min-width: 100%;
						margin-bottom: 10px;
						font-size: 0.9rem;
						line-height: 1rem;
					}

				}
			}
			.contentTwoRight{
				min-width: 40vw;
				min-height: 20%;
				display: flex;
				justify-content: center;
				align-items: flex-start;
				margin: 0;
				padding: 0;
				.Foto1,.Foto2,.Foto3,.Foto4,.Foto5,.Foto6{
					margin: 0;
					min-width: 40vw;
					min-height: 20vw;
				}
			}
		}

	}
}

}

















@media (max-width: 390px){





	$animationSpeed: 20s;
	
	// Animation
	@keyframes scrollAdelante {
		0% { transform: translateX(0); }
		100% { transform: translateX(calc(+90vw * 6))}
	}
	
	
	// Styling
	.sliderTwo {
		min-height: 80vh;
		&::after {
			right: 0;
			top: 0;
			transform: rotateZ(180deg);
		}
	
		&::before {
			left: 0;
			top: 0;
		}
		
		.slide-track {
			animation: scrollAdelante $animationSpeed linear infinite;
			display: flex;
			width: calc(90vw * 12);
		}
		
		.slide {
			min-width: 80vw;
			.contentOne, .contentTwo{
				min-width: 90vw;
			}
			display: flex;
			justify-content: center;
			align-items: flex-start;
			.contentOne{
				margin-top: 10vh;
				padding-top: 0vh;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				.contentOneLeft{
					margin: 0;
					min-width: 45vw;
					min-height: 15vh;
					display: flex;
					justify-content: flex-start;
					align-items: center;
					margin-top: 5vh;
					padding: 0;
					.Foto1,.Foto2,.Foto3,.Foto4,.Foto5,.Foto6{
						margin: 0;
						min-width: 40vw;
						min-height: 20vw;
						background-position: left;
					}				
				}
				.contentOneRight{
					min-width: 45vw;
					min-height: 60%;
					padding-top: 0;
					margin: 0;
					display: flex;
					text{				
						padding: 0;
						margin: 0;
						display: flex;
						justify-content: flex-start;
						align-items: flex-start;
						.Title{
							margin: 0;
							min-width: 100%;
							min-height: 7vh;
							margin-bottom: 10px;
							font-size: 1.2rem;
							text-align: left;
						}
						.Content{
							min-width: 100%;
							margin-bottom: 10px;
							font-size: 1rem;
							line-height: 1rem;
							text-align: left;
						}
						.Client{
							min-width: 100%;
							margin-bottom: 10px;
							font-size: 0.9rem;
							line-height: 1rem;
						}
	
					}
				}
			}
		
	
	
	
			.contentTwo{
				display: flex;
				flex-direction: column-reverse;
	
				.contentTwoLeft{
					min-width: 40vw;
					min-height: 60%;					
					text{					
						.Title{
	
							min-width: 100%;
							margin-bottom: 10px;
							font-size: 1.2rem;
						}
						.Content{
							min-width: 100%;
							margin-bottom: 10px;
							font-size: 1rem;
							line-height: 1rem;
						}
						.Client{
							min-width: 100%;
							margin-bottom: 10px;
							font-size: 0.9rem;
							line-height: 1rem;
						}
	
					}
				}
				.contentTwoRight{
					min-width: 40vw;
					min-height: 20%;
					display: flex;
					justify-content: center;
					align-items: flex-start;
					margin: 0;
					padding: 0;
					.Foto1,.Foto2,.Foto3,.Foto4,.Foto5,.Foto6{
						margin: 0;
						min-width: 40vw;
						min-height: 20vw;
					}
				}
			}
	
		}
	}
	
	}