
@media (max-width: 700px) {
    div{
        .containerFormContact{
            display: none;
        }
        
    #containerFormContactMovil{
        min-height: 70vh;
        color: white;
        display: flex;
        padding-top: 15vh;
        flex-direction: column;
        align-items: center;
    
        .formTitle{
            width: 85vw;
            border-top: 1px solid rgba(255,255,255, .3);
            display: flex;
            flex-direction: column;
            .formTitleLeft{
                width: 50%;
                display: flex;
                height: 8vh;
                align-items: center;
                justify-content: flex-start;
                font-family: Montserrat;
                text-align: right;
                width: 50%;
                font-size: 1.2rem;
            }
            .formTitleRight{
                display: none;
            }
        }
    
        .containerFormContent{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 80vw;
            margin-top: 5vh;
            .containerFormContentLeft{
                display: flex;
                flex-direction: column;
                min-width: 40vw;
    
                /* ESTILOS POPUP*/
                .modal {
                    font-size: 12px;
                  }
                  .modal > .header {
                    width: 100%;
                    border-bottom: 1px solid gray;
                    font-size: 18px;
                    text-align: center;
                    padding: 5px;
                  }
                  .modal > .content {
                    width: 100%;
                    padding: 10px 5px;
                  }
                  .modal > .actions {
                    width: 100%;
                    padding: 10px 5px;
                    margin: auto;
                    text-align: center;
                  }
                  .modal > .close {
                    cursor: pointer;
                    position: absolute;
                    display: block;
                    padding: 2px 5px;
                    line-height: 20px;
                    right: -10px;
                    top: -10px;
                    font-size: 24px;
                    background: #ffffff;
                    border-radius: 18px;
                    border: 1px solid #cfcece;
                  }
                /*FIN DE ESTILOS POPPUO*/
    
    
    
                .subtitleForm{
                    min-width: 40vw;
                    display: flex;
                    flex-direction: column;
                    text{
                        min-width: 20vw;
                        font-size: 1.1rem;
                        font-weight: 200;
                        font-family: Montserrat;
                        color: #FFFFFF;
                        .gray{
                           color: #707070;
                       }
                    }
                }
                .inputForm,  .subtitleForm{
                    min-width: 40vw;
                    display: flex;
                    flex-direction: column;
                    text{
                        min-width: 20vw;
                        font-size: 1.1rem;
                        font-weight: 200;
                        font-family: Montserrat;
                        color: #FFFFFF;
                        .gray{
                           color: #707070;
                       }
                    }
                    input{
                        flex-grow: 1;
                        font-size: 1.2rem;
                        font-family: Montserrat;
                        min-height: 40px;
                        margin-top: 5px;
                        margin-right: 5px;
                        margin-bottom: 20px;
                        color: #FFFFFF;
                    }
                    .message{
                        min-height: 10vh;
                    }
                    input[type="text"] , input[type="date"]{    
                        background-color : #707070;
                        color: white;
                        font-size: 1rem;
                        padding-left: 10px;
                        border:0px;   
                      }
                      input[type="date"]{    
                        background-color : #707070;
                        color: rgb(255, 255, 255,0.6);
                        font-size: 1rem;
                        padding-left: 10px;
                        border:0px;   
                      }
                     
                     
                      
                }
    
                .inputRadius{
                    min-width: 40vw;
                    display: flex;
                    flex-direction: column;
                    div > label{
                        font-family:  HelveticaRegular;
                    }
                    div{
                        margin-bottom: 5vw;
                        flex-grow: 1;
                        display: flex;
                        flex-direction: column;
                        label{
                            margin-bottom: 8px;
                            font-style: HelveticaRegular;
                        }
                        .title{
                            font-family:  HelveticaNeue;
                        }
                    }
                    border-bottom: none;
                }
    
                button[type="submit"]{  
                    background-color: transparent; 
                    cursor: pointer; 
                    position: relative;
                    color: white;
                    font-size: 1.5rem;
                    text-align: left;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    min-height: 50px;
                    min-width: 20%;
                    border:0px;   
                    text{                    
                        width: 80%;
                        margin-right: 8%;
                    }
                    margin-top: 5vh;                
                    border-bottom: 1px solid rgba(255,255,255, .3);
                }
            }
            .containerFormContentRight{
              display: none;
            }
        }
    }
    input::placeholder {
        color: rgb(255, 255, 255,0.6);
        font-weight: 400;
        font-size: 0.9rem;
      }
      .message {    
        margin: 0;
        padding: 0;
      }
      .textarea{
         max-width: 75vw;
         min-width: 60vw;
        font-weight: 400;
        font-size: 1rem;
        padding-top: 10px;
        padding-left: 10px;
        margin-top: 5px;
        margin-bottom: 10px;
        font-family: Helvetica;
        color: rgb(255, 255, 255,0.6);
        background-color: #707070;
      }
    .boton{
    }
    
    }
    
    }
    


@media (max-width: 1000px) and (orientation: landscape) {


        div{
            .containerFormContact{
                display: none;
            }
            
        #containerFormContactMovil{
            min-height: 90vh;
            color: white;
            display: flex;
            padding-top: 15vh;
            flex-direction: column;
            align-items: center;
        
            .formTitle{
                width: 85vw;
                border-top: 1px solid rgba(255,255,255, .3);
                display: flex;
                flex-direction: column;
                .formTitleLeft{
                    width: 50%;
                    display: flex;
                    height: 8vh;
                    align-items: center;
                    justify-content: flex-start;
                    font-family: Montserrat;
                    text-align: right;
                    width: 50%;
                    font-size: 1.2rem;
                }
                .formTitleRight{
                    display: none;
                }
            }
        
            .containerFormContent{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                width: 80vw;
                margin-top: 5vh;
                .containerFormContentLeft{
                    display: flex;
                    flex-direction: column;
                    min-width: 40vw;
        
                    /* ESTILOS POPUP*/
                    .modal {
                        font-size: 12px;
                      }
                      .modal > .header {
                        width: 100%;
                        border-bottom: 1px solid gray;
                        font-size: 18px;
                        text-align: center;
                        padding: 5px;
                      }
                      .modal > .content {
                        width: 100%;
                        padding: 10px 5px;
                      }
                      .modal > .actions {
                        width: 100%;
                        padding: 10px 5px;
                        margin: auto;
                        text-align: center;
                      }
                      .modal > .close {
                        cursor: pointer;
                        position: absolute;
                        display: block;
                        padding: 2px 5px;
                        line-height: 20px;
                        right: -10px;
                        top: -10px;
                        font-size: 24px;
                        background: #ffffff;
                        border-radius: 18px;
                        border: 1px solid #cfcece;
                      }
                    /*FIN DE ESTILOS POPPUO*/
        
        
        
                    .subtitleForm{
        
                        min-width: 40vw;
                        display: flex;
                        flex-direction: column;
                        text{
                            min-width: 20vw;
                            font-size: 1.1rem;
                            font-weight: 200;
                            font-family: Montserrat;
                            color: #FFFFFF;
                            .gray{
                               color: #707070;
                           }
                        }
                    }
                    .inputForm,  .subtitleForm{
                        min-width: 40vw;
                        display: flex;
                        flex-direction: column;
                        text{
                            min-width: 20vw;
                            font-size: 1.1rem;
                            font-weight: 200;
                            font-family: Montserrat;
                            color: #FFFFFF;
                            .gray{
                               color: #707070;
                           }
                        }
                        input{
                            flex-grow: 1;
                            font-size: 1.2rem;
                            font-family: Montserrat;
                            min-height: 40px;
                            margin-top: 5px;
                            margin-right: 5px;
                            margin-bottom: 20px;
                            color: #FFFFFF;
                        }
                        .message{
                            min-height: 10vh;
                        }
                        input[type="text"] , input[type="date"]{    
                            background-color : #707070;
                            color: white;
                            font-size: 1rem;
                            padding-left: 10px;
                            border:0px;   
                          }
                          input[type="date"]{    
                            background-color : #707070;
                            color: rgb(255, 255, 255,0.6);
                            font-size: 1rem;
                            padding-left: 10px;
                            border:0px;   
                          }
                         
                         
                          
                    }
        
                    .inputRadius{
                        min-width: 40vw;
                        display: flex;
                        flex-direction: row;
                        div > label{
                            font-family:  HelveticaRegular;
                        }
                        div{
                            margin-bottom: 5vw;
                            flex-grow: 1;
                            display: flex;
                            flex-direction: column;
                            label{
                                margin-bottom: 8px;
                                font-style: HelveticaRegular;
                            }
                            .title{
                                font-family:  HelveticaNeue;
                            }
                        }
                        border-bottom: none;
                    }
        
                    button[type="submit"]{  
                        background-color: transparent; 
                        cursor: pointer; 
                        position: relative;
                        color: white;
                        font-size: 1.5rem;
                        text-align: left;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        min-height: 50px;
                        min-width: 20%;
                        border:0px;   
                        text{                    
                            width: 80%;
                            margin-right: 8%;
                        }
                        border-bottom: 1px solid rgba(255,255,255, .3);
                    }
                }
                .containerFormContentRight{
                  display: none;
                }
            }
        }
        input::placeholder {
            color: rgb(255, 255, 255,0.6);
            font-weight: 400;
            font-size: 0.9rem;
          }
          .message {    
            margin: 0;
            padding: 0;
          }
          .textarea{
             max-width: 75vw;
             min-width: 60vw;
            font-weight: 400;
            font-size: 1rem;
            padding-top: 10px;
            padding-left: 10px;
            margin-top: 5px;
            margin-bottom: 10px;
            font-family: Helvetica;
            color: rgb(255, 255, 255,0.6);
            background-color: #707070;
          }
        .boton{
        }
        
        }
        
        
        

}