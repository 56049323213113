@media (max-width: 1180px)and(orientation: landscape) {
    .containerTransicion{
      
        min-width: 25vw;
        max-width: 25vw;
        min-height: 40vh;
        max-height: 40vh;
        display: flex;
        justify-content: center;
        align-items: center;
        .Imagen1{
            position: absolute;
            max-width: 27.5vw;
            min-width: 27.5vw;
            max-height: 40vh;
            min-height: 40vh;
        }
        @keyframes AnimacionUno {
            0%{
                opacity: 1;
            }
            50%{
                opacity: 0;
            }
            100%{
                opacity: 1;
            }        
        }
       
        .Imagen2{
            position: absolute;
            z-index: 20;
            max-width: 29vw;
            max-height: 40vh;
            animation: AnimacionUno 4s infinite;
        }
    }
    
}














@media (max-width: 1180px)and(orientation: portrait) {
    .containerTransicion{

        min-width: 25vw;
        max-width: 25vw;
        min-height: 40vh;
        max-height: 40vh;
        display: flex;
        justify-content: center;
        align-items: center;
        .Imagen1{
            position: absolute;
            max-width: 36vw;
            min-width: 36vw;
            max-height: 30vh;
            min-height: 30vh;
        }
        @keyframes AnimacionUno {
            0%{
                opacity: 1;
            }
            50%{
                opacity: 0;
            }
            100%{
                opacity: 1;
            }        
        }
       
        .Imagen2{
            position: absolute;
            z-index: 20;
            max-width: 37vw;
            min-height: 30vh;
            max-height: 30vh;
            animation: AnimacionUno 4s infinite;
        }
    }
    
}