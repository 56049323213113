.containerHomePage{
    z-index: 12;
}
.FooterServicios{
    background: linear-gradient(#000, #FF4700); 
}
#filtroHome{
    z-index: 0;    
    position: fixed;
    margin-top: 0;
    min-width: 100vw;
    min-height: 100vh;
    background-color: #FF4700;
}


@media (max-width: 700px)  {
    .containerHomePage{
        z-index: 12;
    }
    #filtroHome{
        z-index: 4;    
        position: fixed;
        margin-top: 0;
        min-width: 100vw;
        min-height: 100vh;
        background-color: #FF4700;
    }
   
    .absoluteServices{
        min-height: 638vh;
    }
}    