@media (max-width: 1180px)and(orientation: landscape) {


    .containerContentPageThree{
        z-index: 50;
        min-width: 100vw;
        min-height: 80vh;
        margin-top: 0;
        display: flex;    
        flex-direction: column;
        align-items: center;
    }
    
    .pageThreeContent{
        z-index: 50;
        border-top: 1px solid rgba(255,255,255, .3);
        min-width: 90vw;
        max-width: 90vw;
        min-height: 80vh;
        .containerTitleContentPageThree{
            min-width:100%;
            min-height: 10vh;
            display: flex;
            flex-direction: row;
            .containerTitleContentPageThreeLeft{
                display: flex;
                width: 50%;
                min-height: 5vh;
                display: flex;
                align-items: center;
                color: #FFF;
                font-size: 1rem;
                font-family: Montserrat;
                img{
                    width: 1.3vw;
                    margin-right: 2%;
                }
            }
            .containerTitleContentPageThreeRight{
                display: flex;
                width: 50%;
                align-items: center;
                justify-content: flex-end;
                color: #FFF;
                font-family:  Montserrat;
                font-size: 1.6rem;
                .selectText{
                    color: #FF4700;
                }
            }
        }
    
        .submenuContainerPageThree{
            min-height: 55vh;
            display: flex;
            min-width: 90vw;
            .submenuLeftContainer{
                width : 45%;
                min-height: 55vh;
                .submenuLeftContainerIcons{
                    z-index: 20;
                    display: flex;                
                    align-items: flex-start;
                    min-width: 100%;
                    min-height: 30vh;
                    img{
                        transition: 1s;
                        transition-duration: 1s;
                        cursor: pointer;
                        margin-right:8%;
                        //min-width: 4vw;
                        //min-width: ;
                    }                
                }
                .submenuLeftContainerBottom{
                    display: flex;     
                    flex-direction: column;      
                    min-width: 100%;
                    min-height: 25vh;
                    .submenuRightContainerHeader{
                        position: absolute;
                        width: 40%;
                        min-height: 5vh;
                        max-height: 5vh;
                        margin-top: 3%;
                        display: flex;
                        align-items: flex-end;
                        justify-content: center;
                        font-family: SummerFaith;
                        font-size: 10rem;
                        color: white;
                        transform: rotate(-8deg);
                    }
                    .submenuRightContainerBottom{
                        width: 100%;
                        min-height: 15vh;
                        display: flex;
                        flex-direction: row;
                        .submenuRightContainerBottomLeft{
                            display: flex;
                            justify-content: flex-end;
                            align-items: flex-start;
                            width: 60%;
                            color: #FF4700;                        
                            .branding{
                                max-height: 20vh;
                                display: flex;
                                transform: rotate(-15deg);
                                font-family: SummerFaith;
                                font-size: 11vw;
                                margin-top: 0vh;
                                min-width: 40%;
                            }
                            .Design{
                                min-width: 60%;
                                transform: rotate(-15deg);
                                font-family: SummerFaith;
                                font-size: 13vw;
                                font-weight: 500;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                            .Web{
                                min-width: 60%;
                                max-height: 20vh;
                                transform: rotate(-12deg);
                                font-family: SummerFaith;
                                font-size: 15vw; 
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }   
                        .submenuRightContainerBottomRight{
                            transform: rotate(-10deg);
                            display: flex;
                            align-items: flex-start;
                            justify-content: center;
                            width: 40%;
                            max-height: 20vh;
                           
                            color: #FFF;
                            font-family: SummerFaith;
                            font-size: 10rem;
                        }
                    }
                }
            }
            .submenuRightContainer{
                min-width : 55vw;
                min-height: 55vh;           
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-end;
                color: #FFF;           
               ul{          
                transform: 1s;  
                min-width: 80%;
                max-width: 80%;
                font-family:  Montserrat;
                list-style: none;
                font-size: 1.8rem;            
                li{
                    border-bottom: 0.5px solid rgba(255,255,255, 0.4);
                    min-height: 8vh;
                    display: flex;
                    align-items: center;
    
    
                    background-image:   linear-gradient(to right, #C9C9C9 100%, #C9C9C9 100%);
                    background-size:    90% 100%;//,
                    background-position:   0% 100%;
    
                    background-repeat: no-repeat;
                    background-image:   linear-gradient(to right, #C9C9C9 100%, #C9C9C9 100%);
                    background-size:    100% 0.5%;//,
                    background-position:   0% 100%;        
                    .title{
                        cursor: pointer;
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        text {
                            color: #FFF;
                            min-height: 8vh;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            width: 90%;
                            font-family: Montserrat;
                            font-size: 1.5rem;
                        } 
                        .opcion{
                            color: #FFF;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 10%;
                            font-size: 5rem;
                            .menos{                           
                                position: absolute;
                            }
                        }                    
                    }   
                         
                   
                    
                }   
                
                li:hover{
                    animation: TitleHoverSub 2.5s cubic-bezier(0.19, 1, 0.22, 1) 1;
                }
                @keyframes TitleHoverSub {
                    0% {
                        background-size:  0% 1.5px,
                    }                                       
                    100% {
                        background-size:    100% 1.5px,                                        
                    }
                }
               
                .selected{
                    transition:  2s;
    
                    margin: 0;
                    padding: 0;
                    background: transparent;
                    min-height: 8vh;
                    margin: 0;
                    padding: 0;
                    border: none;
                    
                    flex-direction: column;
                        animation: none;
                        display: flex;
                        align-items: flex-start;   
                        justify-content: flex-start;
                    .titleselect{
                        cursor: pointer;    
                            animation: none;
                        display: flex;
                        flex-direction: row;
                        min-width: 100%;
                        
                        text {
                            font-size: 1.5rem;
                            min-width: 90%;
                            max-width: 90%;
                            color: #FF4700;
                            min-height: 8vh;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                        } 
                        .opcion{
                            
                            color: #FF4700;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            min-width: 10%;
                            max-width: 10%;                     
                            font-size: 5rem;
                            font-weight: normal;
                            font-weight: lighter;
                            text-decoration: none;
                        }
                       
                    }   
                            
                    ul{
                        position: relative;
                        border: none;
                        margin: 0;
                        padding: 0;
                        top: 0;
                        left: 0;
                        min-width: 100%;
                        font-size: 1.2rem;
                        animation: DesplegarMenu 1.5s;
                        margin-top: -1.2vh;
                        li{
    
    
    
                            background-image:   linear-gradient(to right, #C9C9C9 100%, #C9C9C9 100%);
                            background-size:    90% 100%;//,
                            background-position:   0% 100%;
            
                            background-repeat: no-repeat;
                            background-image:   linear-gradient(to right, #C9C9C9 100%, #C9C9C9 100%);
                            background-size:    100% 0%;//,
                            background-position:   0% 100%;         
    
    
                            margin: 0;
                            padding: 0;
                            color: #939393;
                            border: none;
                            min-height: 5vh;
                            width: 100%;
                            animation: aparecerDespues 1.5s;        
                            border: 0px solid black;
                        }
                    }
                    
                    
                }  
                @keyframes aparecerDespues {
                    0%{               
                        color: transparent;
                        min-height: 0vh;
                        max-height: 0vh;     
                    }
                    100%{
                        color: #939393;
                        min-height: 5vh;
                        max-height: 5vh;    
                    }
                    
                }
                
                
                @keyframes DesplegarMenu {
                    0%{               
                        min-width: 100%;
                        max-width: 100%; 
                        min-height: 0px;
                        max-height: 0px;               
                    }
                
                    100%{
                        min-width: 100%;
                        max-width: 100%;
                        min-height: 100%;
                        max-height: 100%;      
                    }
                    
                }
               }
            }
        }
    }
    
    
}














@media (max-width: 820px ) and  (orientation:portrait) {



    .containerContentPageThree{
        z-index: 50;
        min-width: 100vw;
        min-height: 80vh;
        margin-top: 0;
        display: flex;    
        flex-direction: column;
        align-items: center;
    }
    
    .pageThreeContent{
        z-index: 50;
        border-top: 1px solid rgba(255,255,255, .3);
        min-width: 90vw;
        max-width: 90vw;
        min-height: 80vh;
        .containerTitleContentPageThree{
            min-width:100%;
            min-height: 10vh;
            display: flex;
            flex-direction: row;


            .containerTitleContentPageThreeLeft{
                display: flex;
                width: 50%;
                min-height: 5vh;
                display: flex;
                align-items: center;
                color: #FFF;
                font-size: 1rem;
                font-family: Montserrat;
                img{
                    width: 1.3vw;
                    margin-right: 2%;
                }
            }
            .containerTitleContentPageThreeRight{
                display: flex;
                width: 50%;
                align-items: center;
                justify-content: flex-end;
                color: #FFF;
                font-family:  Montserrat;
                font-size: 1.6rem;
                .selectText{
                    color: #FF4700;
                }
            }
        }
    
        .submenuContainerPageThree{
            min-height: 55vh;
            display: flex;
            min-width: 90vw;
            flex-direction: column;

            .submenuLeftContainer{
                width : 90%;
                min-height: 30vh;
               
                .submenuLeftContainerIcons{
                    
                    z-index: 20;
                    display: flex;                
                    align-items: flex-start;
                    min-width: 100%;
                    min-height: 30vh;
                    display: none;
                    img{
                        transition: 1s;
                        transition-duration: 1s;
                        cursor: pointer;
                        margin-right:8%;
                        //min-width: 4vw;
                        //min-width: ;
                    }                
                }
                .submenuLeftContainerBottom{
                    display: flex;     
                    flex-direction: column;      
                    min-width: 100%;
                    min-height: 10vh;
                    margin-top: 8vh;
                    .submenuRightContainerHeader{
                        position: absolute;
                        width: 80%;
                        min-height: 5vh;
                        max-height: 5vh;
                        margin-top: 3%;
                        display: flex;
                        align-items: flex-end;
                        justify-content: center;
                        font-family: SummerFaith;
                        font-size: 10rem;
                        color: white;
                        transform: rotate(-8deg);

                    }
                    .submenuRightContainerBottom{
                        width: 100%;
                        min-height: 15vh;
                        display: flex;
                        flex-direction: row;
                        .submenuRightContainerBottomLeft{
                            display: flex;
                            justify-content: flex-end;
                            align-items: flex-start;
                            width: 60%;
                            color: #FF4700;                        
                            .branding{
                                max-height: 20vh;
                                display: flex;
                                transform: rotate(-15deg);
                                font-family: SummerFaith;
                                font-size: 15vw;
                                margin-top: 0vh;
                                min-width: 40%;
                            }
                            .Design{
                                min-width: 60%;
                                transform: rotate(-15deg);
                                font-family: SummerFaith;
                                font-size: 18vw;
                                font-weight: 500;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                            .Web{
                                min-width: 60%;
                                max-height: 20vh;
                                transform: rotate(-12deg);
                                font-family: SummerFaith;
                                font-size: 18vw; 
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }   
                        .submenuRightContainerBottomRight{
                            transform: rotate(-10deg);
                            display: flex;
                            align-items: flex-start;
                            justify-content: center;
                            width: 40%;
                            max-height: 20vh;
                           
                            color: #FFF;
                            font-family: SummerFaith;
                            font-size: 10rem;
                        }
                    }
                }
            }
            .submenuRightContainer{
                min-width : 55vw;
                min-height: 55vh;           
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-end;
                color: #FFF;           
                margin: 0;
                padding: 0;
               ul{         
                   margin: 0;
                   padding: 0; 
                transform: 1s;  
                min-width: 100%;
                max-width: 100%;
                font-family:  Montserrat;
                list-style: none;
                font-size: 1.8rem;            
                li{
                    border-bottom: 0.5px solid rgba(255,255,255, 0.4);
                    min-height: 5vh;
                    display: flex;
                    align-items: center;
    
    
                    background-image:   linear-gradient(to right, #C9C9C9 100%, #C9C9C9 100%);
                    background-size:    90% 100%;//,
                    background-position:   0% 100%;
    
                    background-repeat: no-repeat;
                    background-image:   linear-gradient(to right, #C9C9C9 100%, #C9C9C9 100%);
                    background-size:    100% 0.5%;//,
                    background-position:   0% 100%;        
                    .title{
                        cursor: pointer;
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        text {
                            color: #FFF;
                            min-height: 8vh;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            width: 90%;
                            font-family: Montserrat;
                            font-size: 1.5rem;
                        } 
                        .opcion{
                            color: #FFF;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 10%;
                            font-size: 5rem;
                            .menos{                           
                                position: absolute;
                            }
                        }                    
                    }   
                         
                   
                    
                }   
                
                li:hover{
                    animation: TitleHoverSub 2.5s cubic-bezier(0.19, 1, 0.22, 1) 1;
                }
                @keyframes TitleHoverSub {
                    0% {
                        background-size:  0% 1.5px,
                    }                                       
                    100% {
                        background-size:    100% 1.5px,                                        
                    }
                }
               
                .selected{
                    transition:  2s;
    
                    margin: 0;
                    padding: 0;
                    background: transparent;
                    min-height: 8vh;
                    margin: 0;
                    padding: 0;
                    border: none;
                    
                    flex-direction: column;
                        animation: none;
                        display: flex;
                        align-items: flex-start;   
                        justify-content: flex-start;
                    .titleselect{
                        cursor: pointer;    
                            animation: none;
                        display: flex;
                        flex-direction: row;
                        min-width: 100%;
                        
                        text {
                            font-size: 1.5rem;
                            min-width: 90%;
                            max-width: 90%;
                            color: #FF4700;
                            min-height: 8vh;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                        } 
                        .opcion{
                            
                            color: #FF4700;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            min-width: 10%;
                            max-width: 10%;                     
                            font-size: 5rem;
                            font-weight: normal;
                            font-weight: lighter;
                            text-decoration: none;
                        }
                       
                    }   
                            
                    ul{
                        position: relative;
                        border: none;
                        margin: 0;
                        padding: 0;
                        top: 0;
                        left: 0;
                        min-width: 100%;
                        font-size: 1.2rem;
                        animation: DesplegarMenu 1.5s;
                        margin-top: -1.2vh;
                        li{
    
    
    
                            background-image:   linear-gradient(to right, #C9C9C9 100%, #C9C9C9 100%);
                            background-size:    90% 100%;//,
                            background-position:   0% 100%;
            
                            background-repeat: no-repeat;
                            background-image:   linear-gradient(to right, #C9C9C9 100%, #C9C9C9 100%);
                            background-size:    100% 0%;//,
                            background-position:   0% 100%;         
    
    
                            margin: 0;
                            padding: 0;
                            color: #939393;
                            border: none;
                            min-height: 5vh;
                            width: 100%;
                            animation: aparecerDespues 1.5s;        
                            border: 0px solid black;
                        }
                    }
                    
                    
                }  
                @keyframes aparecerDespues {
                    0%{               
                        color: transparent;
                        min-height: 0vh;
                        max-height: 0vh;     
                    }
                    100%{
                        color: #939393;
                        min-height: 5vh;
                        max-height: 5vh;    
                    }
                    
                }
                
                
                @keyframes DesplegarMenu {
                    0%{               
                        min-width: 100%;
                        max-width: 100%; 
                        min-height: 0px;
                        max-height: 0px;               
                    }
                
                    100%{
                        min-width: 100%;
                        max-width: 100%;
                        min-height: 100%;
                        max-height: 100%;      
                    }
                    
                }
               }
            }
        }
    }
    
    
}

